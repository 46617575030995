import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const MenuSlice = createSlice({
  name: "MenuSlice",
  initialState: initialState,
  reducers: {
    storeMenuSlice(state, action) {
      const { data } = action.payload;
      state.data = data;
    },
    removeMenuSlice() {
      return initialState;
    },
  },
});

export default MenuSlice.reducer;
export const { storeMenuSlice, removeMenuSlice } = MenuSlice.actions;
