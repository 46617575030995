import axios from "axios";
import AppToast from "shared/associate/app-toast";
import { removeMenuSlice } from "slices/MenuSlice";
import { removeRoleRightsSlice } from "slices/RoleRightsSlice";
import { removeLoginData, storeLoginData } from "slices/LoginDataSlice";
import { BASE_URL } from "./apis";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { refreshTokenApi } from "./apis";

// Custom hook to create the API instance with interceptors
// const useApiInstance = () => {
//   const loggedinInfoRedux = useSelector((state) => state.LoginDataSlice);
//   const dispatch = useDispatch();
//   const history = useHistory();

//   const handleLogout = () => {
//     console.log("inside the handleLogout");
//     dispatch(removeLoginData());
//     dispatch(removeMenuSlice());
//     dispatch(removeRoleRightsSlice());
//     history.push("/");
//   };

//   let isSessionExpiredToastShown = false; // A flag to track whether the session expired toast has been shown

//   // Create an Axios instance
//   const apiInstance = axios.create({
//     baseURL: BASE_URL,
//   });

//   // Function to check if the token is expired using the expiresIn property
//   const isTokenExpired = (expiresIn) => {
//     const expirationDate = new Date(expiresIn); // Convert to Date object
//     const currentDate = new Date(); // Get the current time
//     return expirationDate <= currentDate; // Return true if expired
//   };

//   // const methodHeader = {
//   //   headers: {}, // Authentication header
//   // };

//   const methodHeader = {
//     headers: { "Content-Type": "application/json" }
//   }

//   // REFRESH TOKEN CALL METHOD
//   const refreshToken = async () => {
//     console.log("Under the refresh token");

//     try {
//       // Assuming refresh token logic is implemented via some refreshToken API
//       const refreshedData = await apiInstance.post(refreshTokenApi, {
//         refreshToken: loggedinInfoRedux.data.refreshToken,
//         accessToken: loggedinInfoRedux.data.accessToken,
//         reloadInfo: true,
//       }, methodHeader);

//       const { data } = refreshedData.data;
//       console.log("the refresh data is", data);
//       // Save the new token in Redux
//       dispatch(storeLoginData({ data: data }));
//     } catch (error) {
//       AppToast.error("Faild To Extendes The Session");
//     }
//   };

//   apiInstance.interceptors.request.use(
//     (config) => {
//       const user = loggedinInfoRedux.data;

//       // Check if the token is valid by comparing with expiresIn
//       if (user?.accessToken && isTokenExpired(user.expiresIn)) {
//         // user.expiresIn
//         // "2024-09-19T06:15:00Z"
//         if (!isSessionExpiredToastShown) {
//           refreshToken();
//           isSessionExpiredToastShown = true;
//         }
//       }

//       // If the token is valid, attach it to the request
//       if (user?.accessToken) {
//         config.headers.Authorization = `Bearer ${user.accessToken}`;
//       }
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );

//   apiInstance.interceptors.response.use(
//     (response) => {
//       isSessionExpiredToastShown = false;
//       return response;
//     },
//     (error) => {
//       if (error.response && error.response.status === 500) {
//         const message = error.response.data?.Message?.trim();
//         if (message === "Session Expired or Invalid Session") {
//           if (!isSessionExpiredToastShown) {
//             AppToast.error("User session has expired. Please log in again.");
//             handleLogout();
//             isSessionExpiredToastShown = true;
//           }
//         }
//       }
//       return Promise.reject(error);
//     }
//   );

//   return apiInstance;
// };

// export default useApiInstance;

//  ====================== Working Test Code ===================== //

const useApiInstance = () => {
  const loggedinInfoRedux = useSelector((state) => state.LoginDataSlice);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    // console.log("inside the handleLogout");
    dispatch(removeLoginData());
    dispatch(removeMenuSlice());
    dispatch(removeRoleRightsSlice());
    history.push("/");
  };

  let isRefreshing = false; // To track if a token refresh is in progress
  let failedQueue = []; // Queue to hold failed requests during token refresh

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (token) {
        prom.resolve(token);
      } else {
        prom.reject(error);
      }
    });
    failedQueue = [];
  };

  // Create an Axios instance
  const apiInstance = axios.create({
    baseURL: BASE_URL,
  });

  // Function to check if the token is expired using the expiresIn property
  const isTokenExpired = (expiresIn) => {
    const expirationDate = new Date(expiresIn); // Convert to Date object
    const currentDate = new Date(); // Get the current time
    // console.log("inside isTokenExpired", expirationDate <= currentDate);
    return expirationDate <= currentDate; // Return true if expired
  };

  const methodHeader = {
    headers: { "Content-Type": "application/json" },
  };

  let isSessionExpiredToastShown = false;

  // REFRESH TOKEN CALL METHOD
  const refreshToken = async () => {
    // console.log("Attempting to refresh token...");
    try {
      const refreshedData = await apiInstance.post(
        refreshTokenApi,
        {
          refreshToken: loggedinInfoRedux.data.refreshToken,
          accessToken: loggedinInfoRedux.data.accessToken,
          reloadInfo: true,
        },
        {
          ...methodHeader,
          skipAuth: true,
        }
      );

      const { data } = refreshedData.data;
      // console.log("New token received:", data);

      // Store the new token in Redux
      dispatch(storeLoginData({ data: data }));

      // Return the new access token
      return data.accessToken;
    } catch (error) {
      console.error("Failed to refresh token", error);
      handleLogout(); // Log the user out if the refresh fails
      throw error;
    }
  };

  apiInstance.interceptors.request.use(
    async (config) => {
      const user = loggedinInfoRedux.data;
      if (!config.skipAuth) {
        if (user?.accessToken && isTokenExpired(user.expiresIn)) {
          if (!isRefreshing) {
            isRefreshing = true;

            try {
              const newAccessToken = await refreshToken();
              // console.log("newAccessToken", newAccessToken);

              processQueue(null, newAccessToken);
              isRefreshing = false;
            } catch (error) {
              processQueue(error, null);
              isRefreshing = false;
              throw error;
            }
          }

          return new Promise((resolve, reject) => {
            failedQueue.push({
              resolve: (token) => {
                // console.log("token is given by", token);

                config.headers.Authorization = `Bearer ${token}`;
                resolve(config);
              },
              reject: (err) => {
                reject(err);
              },
            });
          });
        } else if (user?.accessToken) {
          config.headers.Authorization = `Bearer ${user.accessToken}`;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiInstance.interceptors.response.use(
    (response) => {
      return response;
      isSessionExpiredToastShown = false;
    },
    (error) => {
      if (error.response && error.response.status === 500) {
        const message = error.response.data?.Message?.trim();
        if (message === "Session Expired or Invalid Session") {
          if (!isSessionExpiredToastShown) {
            AppToast.error("User session has expired. Please log in again.");
            handleLogout();
            isSessionExpiredToastShown = true;
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

export default useApiInstance;

// ================= Test Code With Refresh Token Error Handeling =============== //

// const useApiInstance = () => {
//   const loggedinInfoRedux = useSelector((state) => state.LoginDataSlice);
//   const dispatch = useDispatch();
//   const history = useHistory();

//   const handleLogout = () => {
//     console.log("Logging out the user...");
//     dispatch(removeLoginData());
//     dispatch(removeMenuSlice());
//     dispatch(removeRoleRightsSlice());
//     history.push("/");
//   };

//   let isRefreshing = false;
//   let failedQueue = [];

//   const processQueue = (error, token = null) => {
//     failedQueue.forEach((prom) => {
//       if (token) {
//         prom.resolve(token);
//       } else {
//         prom.reject(error);
//       }
//     });
//     failedQueue = [];
//   };

//   const apiInstance = axios.create({
//     baseURL: BASE_URL,
//   });

//   const isTokenExpired = (expiresIn) => {
//     const expirationDate = new Date(expiresIn);
//     const currentDate = new Date();
//     return expirationDate <= currentDate;
//   };

//   const methodHeader = {
//     headers: { "Content-Type": "application/json" },
//   };

//   const refreshToken = async () => {
//     console.log("Attempting to refresh token...");
//     try {
//       const refreshedData = await apiInstance.post(
//         refreshTokenApi,
//         {
//           refreshToken: loggedinInfoRedux.data.refreshToken,
//           accessToken: loggedinInfoRedux.data.accessToken,
//           reloadInfo: true,
//         },
//         {
//           ...methodHeader,
//           skipAuth: true,
//         }
//       );

//       const { data } = refreshedData.data;
//       console.log("New token received:", data);

//       // Store the new token in Redux
//       dispatch(storeLoginData({ data: data }));

//       // Return the new access token
//       return data.accessToken;
//     } catch (error) {
//       console.error("Failed to refresh token", error);
//       const errorMessage = error.response?.data?.errors || "Error";

//       if (errorMessage === "Refresh Token has expired. Please login.") {
//         AppToast.error("Your session has expired. Please log in again.");
//         handleLogout();
//       }

//       throw error;
//     }
//   };

//   apiInstance.interceptors.request.use(
//     async (config) => {
//       const user = loggedinInfoRedux.data;
//       if (!config.skipAuth) {
//         if (user?.accessToken && isTokenExpired(user.expiresIn)) {
//           if (!isRefreshing) {
//             isRefreshing = true;

//             try {
//               const newAccessToken = await refreshToken();
//               processQueue(null, newAccessToken);
//               isRefreshing = false;
//             } catch (error) {
//               processQueue(error, null);
//               isRefreshing = false;
//               throw error;
//             }
//           }

//           return new Promise((resolve, reject) => {
//             failedQueue.push({
//               resolve: (token) => {
//                 config.headers.Authorization = `Bearer ${token}`;
//                 resolve(config);
//               },
//               reject: (err) => {
//                 reject(err);
//               },
//             });
//           });
//         } else if (user?.accessToken) {
//           config.headers.Authorization = `Bearer ${user.accessToken}`;
//         }
//       }

//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );

//   apiInstance.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       if (error.response) {
//         const status = error.response.status;
//         const message = error.response.data?.message || "";

//         if (status === 401 && message === "Refresh Token has expired. Please login.") {
//           AppToast.error("Your session has expired. Please log in again.");
//           handleLogout();
//         } else if (status === 500 && message.trim() === "Session Expired or Invalid Session") {
//           AppToast.error("User session has expired. Please log in again.");
//           handleLogout();
//         }
//       }
//       return Promise.reject(error);
//     }
//   );

//   return apiInstance;
// };

// export default useApiInstance;

// ==================== The Refresh Token Impl 2nd Modification ====================== //

// const useApiInstance = () => {
//   const loggedinInfoRedux = useSelector((state) => state.LoginDataSlice);
//   const dispatch = useDispatch();
//   const history = useHistory();

//   const handleLogout = () => {
//     console.log("inside the handleLogout");
//     dispatch(removeLoginData());
//     dispatch(removeMenuSlice());
//     dispatch(removeRoleRightsSlice());
//     history.push("/");
//   };

//   let isRefreshing = false;  // To track if a token refresh is in progress
//   let failedQueue = [];      // Queue to hold failed requests during token refresh

//   const processQueue = (error, token = null) => {
//     failedQueue.forEach((prom) => {
//       if (token) {
//         prom.resolve(token);
//       } else {
//         prom.reject(error);
//       }
//     });
//     failedQueue = [];
//   };

//   // Create an Axios instance
//   const apiInstance = axios.create({
//     baseURL: BASE_URL,
//   });

//   // Function to check if the token is expired using the expiresIn property
//   const isTokenExpired = (expiresIn) => {
//     const expirationDate = new Date(expiresIn); // Convert to Date object
//     const currentDate = new Date(); // Get the current time
//     console.log("inside isTokenExpired", expirationDate <= currentDate);
//     return expirationDate <= currentDate; // Return true if expired
//   };

//   const methodHeader = {
//     headers: { "Content-Type": "application/json" },
//   };

//   // REFRESH TOKEN CALL METHOD
//   const refreshToken = async () => {
//     console.log("Attempting to refresh token...");
//     try {
//       const refreshedData = await apiInstance.post(refreshTokenApi, {
//         refreshToken: loggedinInfoRedux.data.refreshToken,
//         accessToken: loggedinInfoRedux.data.accessToken,
//         reloadInfo: true,
//       }, {
//         ...methodHeader,
//         skipAuth: true,
//       });

//       const { data } = refreshedData.data;
//       console.log("New token received:", data);

//       // Store the new token in Redux
//       dispatch(storeLoginData({ data: data }));

//       // Return the new access token
//       return data.accessToken;
//     } catch (error) {
//       console.error("Failed to refresh token", error);
//       handleLogout(); // Log the user out if the refresh fails
//       throw error;
//     }
//   };

//   apiInstance.interceptors.request.use(
//     async (config) => {
//       const user = loggedinInfoRedux.data;
//       if (!config.skipAuth) {
//         if (user?.accessToken && isTokenExpired(user.expiresIn)) {
//           if (!isRefreshing) {
//             isRefreshing = true;

//             try {
//               const newAccessToken = await refreshToken();
//               console.log("newAccessToken", newAccessToken);

//               processQueue(null, newAccessToken);
//               isRefreshing = false;
//             } catch (error) {
//               processQueue(error, null);
//               isRefreshing = false;
//               throw error;
//             }
//           }

//           return new Promise((resolve, reject) => {
//             failedQueue.push({
//               resolve: (token) => {
//                 console.log("token is given by", token);

//                 config.headers.Authorization = `Bearer ${token}`;
//                 resolve(config);
//               },
//               reject: (err) => {
//                 reject(err);
//               },
//             });
//           });
//         } else if (user?.accessToken) {
//           config.headers.Authorization = `Bearer ${user.accessToken}`;
//         }
//       }

//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );

//   apiInstance.interceptors.response.use(
//     (response) => {
//       // Handle the case where API returns a 200 but the token is expired in the response data
//       if (response.status === 200 && response.data?.succeeded === false && response.data?.errors === "Refresh Token has expired. Please login.") {
//         // Log the user out
//         AppToast.error("Your session has expired. Please log in again.");
//         handleLogout();
//         return Promise.reject(new Error("Refresh Token expired"));
//       }

//       return response;
//     },
//     (error) => {
//       // Handle 500 or any other errors
//       if (error.response && error.response.status === 500) {
//         const message = error.response.data?.Message?.trim();
//         if (message === "Session Expired or Invalid Session") {
//           AppToast.error("User session has expired. Please log in again.");
//           handleLogout();
//         }
//       }
//       return Promise.reject(error);
//     }
//   );

//   return apiInstance;
// };

// export default useApiInstance;
