import { configureStore } from "@reduxjs/toolkit";
import reducers from "../slices";
import { persistReducer, persistStore } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: [
    "LoginDataSlice",
    "MenuSlice",
    "RoleRightsSlice",
    "CurrentPageDataSlice",
  ],
  blacklist: ["ThemeOptions", "Apislice", "DropDownSlicer"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});
export default function setupStore() {
  return store;
}

export const persistor = persistStore(store);
