import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import AppToast from "shared/associate/app-toast";
import { loginDataStorageKey, seassionExpireMsg } from "shared/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BASE_URL } from "./apis";
import apiInstance from "./api_interceptor";
import useApiInstance from "./api_interceptor";

const ServerRequiestWrapper = (Component) => {
  const ServerRequiestComponent = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const loggedinInfoRedux = useSelector((state) => state.LoginDataSlice);
    const history = useHistory();

    const apiInstance = useApiInstance();

    const AuthHeader = (contentType) => {
      return loggedinInfoRedux?.data?.accessToken
        ? {
            "Content-Type": contentType,
            Authorization: "Bearer " + loggedinInfoRedux?.data?.accessToken,

            //Authorization:
            // "Bearer " +
            //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyTmFtZSI6InN1cGVyYWRtaW4iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiIyIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiU3VwZXJBZG1pbiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvdXNlcmRhdGEiOiJ7XCJJZFwiOjIsXCJVc2VyTmFtZVwiOlwic3VwZXJhZG1pblwiLFwiVXNlclR5cGVJZFwiOm51bGwsXCJGaXJzdE5hbWVcIjpcIlN1cGVyXCIsXCJMYXN0TmFtZVwiOlwiQWRtaW5cIixcIlByaU1vYmlsZU5vXCI6XCI5ODk4OTg5ODk4XCIsXCJFbWFpbElkXCI6XCJhZG1pbkBlbG9naXhtYWlsLmNvbVwiLFwiSW5zdGl0dXRlSWRcIjoxLFwiUm9sZUlkXCI6MSxcIlJvbGVOYW1lXCI6XCJTdXBlckFkbWluXCIsXCJEZXB0SWRcIjpudWxsLFwiRGVzaWdJZFwiOm51bGwsXCJGaXJzdFJlcG9ydGluZ1RvXCI6MCxcIkFjY2Vzc1RvXCI6MX0iLCJleHAiOjE3MjEyOTgwMTcsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjcyNzYiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo3Mjc2In0.M4u-bJ5x9ZFBi64Jzs32lQlo-LhFZtG1CdzGhC89WHQ",
          }
        : {};
    };

    // LAST VERSION OF MethodCall WITHOUT IMPLEMENTING AN AXIOS INTERCEPTOR
    // CURRENTLY THIS METHOD IS COMMENTED AND A NEW VERSION WITH INTERCEPTOR WRITTEN BELOW

    // const MethodCall = async (
    //   url,
    //   type,
    //   payLoad,
    //   successsMessage,
    //   fileUpload = false
    // ) => {
    //   const user = loggedinInfoRedux.data;
    //   // const user = getLocalStorageItem(loginDataStorageKey);

    //   if (
    //     url !== "/api/v1/UserAuth/Login" &&
    //     url !== "/api/v1/UserAuth/ForgetPassword" &&
    //     url !== "/api/v1/UserAuth/ValidateWebOtp" &&
    //     url !== "/api/v1/UserAuth/ResetWebPassword" &&
    //     !user?.accessToken
    //   ) {
    //     return;
    //   }
    //   let methodHeader;
    //   if (fileUpload) {
    //     methodHeader = { headers: AuthHeader("multipart/form-data") };
    //   } else {
    //     methodHeader = { headers: AuthHeader("application/json") };
    //   }
    //   try {
    //     const api = axios.create({
    //       baseURL: BASE_URL,
    //     });

    //     let response;
    //     if (type === "POST") {
    //       setLoading(true);
    //       response = await api.post(url, payLoad, methodHeader);
    //     }
    //     if (type === "GET") {
    //       response = await api.get(url, methodHeader);
    //     }
    //     if (response.data.hasOwnProperty("succeeded")) {
    //       const { succeeded, message, errors, data } = response.data;
    //       if (succeeded) {
    //         successsMessage && AppToast.success(successsMessage);
    //         if (Object.hasOwn(response.data, "total")) {
    //           return {
    //             succeeded,
    //             data,
    //             total: response.data.total,
    //             pageNumber: response.data.pageNumber,
    //             pageSize: response.data.pageSize,
    //           };
    //         }
    //         return {
    //           succeeded,
    //           data,
    //         };
    //       } else {
    //         AppToast.error(errors);
    //         // console.error(errors);
    //       }
    //     } else {
    //       return {
    //         data: response.data,
    //       };
    //     }
    //   } catch (error) {
    //     let message;
    //     if (error.response) {
    //       const { status, statusText } = error.response;
    //       if (status === 500) {
    //         const { Message } = error.response.data;
    //         if (Message.trim() === "Session Expired or Invalid Session") {
    //           message = seassionExpireMsg;

    //           AppToast.error(message);
    //           handleLogout();
    //           // throw
    //           throw new Error("User session has expired. Please log in again.");
    //         } else {
    //           message = Message;
    //           AppToast.error(message);
    //         }
    //       } else {
    //         const { data } = error.response;
    //         message = data.title;
    //         AppToast.error(message);
    //       }
    //     }
    //     // message && AppToast.error(message);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // NEW MethodCall FUNCTION WITH AXIOS INTERCEPTOR IMPLEMENTED
    const MethodCall = async (
      url,
      type,
      payLoad,
      successMessage,
      fileUpload = false
    ) => {
      const user = loggedinInfoRedux.data;

      if (
        !user?.accessToken &&
        ![
          "/api/v1/UserAuth/Login",
          "/api/v1/UserAuth/ForgetPassword",
          "/api/v1/UserAuth/ValidateWebOtp",
          "/api/v1/UserAuth/ResetWebPassword",
        ].includes(url)
      ) {
        return;
      }

      let methodHeader = {
        headers: fileUpload
          ? { "Content-Type": "multipart/form-data" }
          : { "Content-Type": "application/json" },
      };

      try {
        let response;
        if (type === "POST") {
          setLoading(true);
          response = await apiInstance.post(url, payLoad, methodHeader);
        } else if (type === "GET") {
          response = await apiInstance.get(url, methodHeader);
        }

        if (response.data?.succeeded) {
          successMessage && AppToast.success(successMessage);
          return response.data;
        } else {
          AppToast.error(
            response.data?.errors ||
              "Some error occurred, please try again later."
          );
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    // LAST VERSION OF MethodCallBlob WITHOUT IMPLEMENTING AN AXIOS INTERCEPTOR
    // CURRENTLY THIS METHOD IS COMMENTED AND A NEW VERSION WITH INTERCEPTOR WRITTEN BELOW

    // const MethodCallBlob = async (url, type, payLoad, successsMessage) => {
    //   // const user = getLocalStorageItem(loginDataStorageKey);
    //   const user = loggedinInfoRedux.data;
    //   if (
    //     url !== "/api/v1/UserAuth/Login" &&
    //     !loggedinInfoRedux?.data?.accessToken
    //   ) {
    //     return;
    //   }
    //   const methodHeader = {
    //     headers: AuthHeader(),
    //     responseType: "blob", // Specify the response type here
    //   };
    //   try {
    //     const api = axios.create({
    //       baseURL: BASE_URL,
    //     });

    //     let response;
    //     if (type === "POST") {
    //       setLoading(true);
    //       response = await api.post(url, payLoad, methodHeader);
    //     }
    //     if (type === "GET") {
    //       response = await api.get(url, methodHeader);
    //     }
    //     if (response.data.hasOwnProperty("succeeded")) {
    //       const { succeeded, message, errors, data } = response.data;
    //       if (succeeded) {
    //         successsMessage && AppToast.success(successsMessage);
    //         return {
    //           succeeded,
    //           data,
    //         };
    //       } else {
    //         AppToast.error(errors);
    //       }
    //     } else {
    //       return {
    //         data: response.data,
    //       };
    //     }
    //   } catch (error) {
    //     let message;
    //     if (error.response) {
    //       const { status, statusText } = error.response;
    //       if (status === 500) {
    //         const { Message } = error.response.data;
    //         const messageContent = (Message || "").trim();
    //         if (messageContent === "Session Expired or Invalid Session") {
    //           message = seassionExpireMsg;
    //           AppToast.error(message);
    //           handleLogout();
    //         } else {
    //           message = Message;
    //           AppToast.error(message);
    //         }
    //       } else {
    //         const { data } = error.response;
    //         message = data.title;
    //         AppToast.error(message);
    //       }
    //     }
    //     // message && AppToast.error(message);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // NEW MethodCallBlob FUNCTION WITH AXIOS INTERCEPTOR IMPLEMENTED
    const MethodCallBlob = async (url, type, payLoad, successMessage) => {
      const user = loggedinInfoRedux.data;

      // Check for valid accessToken unless it's a login request
      if (url !== "/api/v1/UserAuth/Login" && !user?.accessToken) {
        return;
      }

      const methodHeader = {
        headers: AuthHeader(), // Authentication header
        responseType: "blob", // Specify response type as 'blob'
      };

      try {
        let response;

        // Determine request type
        if (type === "POST") {
          setLoading(true);
          response = await apiInstance.post(url, payLoad, methodHeader);
        } else if (type === "GET") {
          response = await apiInstance.get(url, methodHeader);
        }

        if (response.data.hasOwnProperty("succeeded")) {
          const { succeeded, message, errors, data } = response.data;
          if (succeeded) {
            successMessage && AppToast.success(successMessage);
            return {
              succeeded,
              data,
            };
          } else {
            AppToast.error(
              response.data?.errors ||
                "Some error occurred, please try again later."
            );
          }
        } else {
          // Return blob data directly
          return {
            data: response.data,
          };
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Component MethodCall={MethodCall} MethodCallBlob={MethodCallBlob} />
      </>
    );
  };
  return ServerRequiestComponent;
};

export default ServerRequiestWrapper;
