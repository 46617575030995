export const checkFiles = (files) => {
  let isvalid = false;
  for (let index = 0; index < files.length; index++) {
    const { type, size } = files[index];

    if ((type === "image/jpeg" || type === "image/png") && size <= 5242880) {
      isvalid = true;
      break;
    }
  }

  return { isvalid };
};

export const checkFiles_BudgetDoc = (files) => {
  let isvalid = false;

  const allowedFileTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];
  const sizeLimit = 10485760;

  for (let index = 0; index < files.length; index++) {
    const { type, size } = files[index];

    if (allowedFileTypes.includes(type)) {
      isvalid = true;
      break;
    }
  }

  return { isvalid };
};

export const checkFiles_size_BudgetDoc = (files) => {
  let issizevalid = false;

  const sizeLimit = 10485760;

  for (let index = 0; index < files.length; index++) {
    const { type, size } = files[index];

    if (size <= sizeLimit) {
      issizevalid = true;
      break;
    }
  }

  return { issizevalid };
};

