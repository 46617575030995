import { Bounce, toast } from 'react-toastify'

const toastCustomisation = {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "top-right"
}
const AppToast = {

    success: (message) => toast(
        message,
        {
            ...toastCustomisation,
            type: 'success'
        }
    ),
    error: (message) => toast(
        message,
        {
            ...toastCustomisation,
            type: 'error'
        }
    )

}

export default AppToast