// success messages

import { createActionIcon } from "./associate/create-action-icon-object";

export const loginSuccessMsg = "User Logged in successfully";
export const userCreationSuccessMsg = "User created successfully";
export const userUpdationSuccessMsg = "User Updated successfully";
export const DeleteUserSuccessMsg = "User deleted successfully";

export const SaveDesignationSuccessMsg = "Designation saved successfully";
export const UpdatedDesignationSuccessMsg = "Designation updated successfully";
export const DeleteDesignationSuccessMsg = "Designation deleted successfully";
export const DesignationStatusUpdateMsg = "Status updated successfully";

export const SaveDepartmentSuccessMsg = "Department saved successfully";
export const UpdateDepartmentSuccessMsg = "Department Updated successfully";
export const DeleteDepartmentSuccessMsg = "Department deleted successfully";
export const DepartmentStatusUpdateMsg = "Status updated successfully";

export const useNameErrMsg = "Enter a Valid username";
export const useTypeErrMsg = "User Type should not be empty";
export const useNameEmptyMsg = "Enter a username";
export const seassionExpireMsg = "Your session has expired. Please login again";
export const passwordErrMsg = "Password should be 8 charecters long";
export const phnNoErrMsg = "Phone number should be number of 10 digits";
export const nameErrMsg = "Name should be of 3-50 charecters";
export const otpErrMsg = "Enter valid otp";
export const departmentCodeRequiredErrMsg =
  "Department Code should not be empty";
export const designationErrMsg = "Designation Name should not be empty";
export const deptnameErrMsg = "Department Name should not be empty";
export const desigCodeErrMsg = "Designation Code should not be empty";
export const emailErrMsg = "Enter a Valid email";
export const deptErrMsg = "Select department";
export const institutionErrMsg = "Institution Name should not be empty ";
export const roleErrMsg = "Role should not be empty";
export const desigErrMsg = "Select designation";
export const codeErrMsg = "Code will not contain any special character";
export const instituteErrMsg = "Please select a Institution";
export const UserStatusUpdateMsg = "Status updated successfully";

export const assignrightssuccessMsg = "Right assigned successfully";

export const SaveSurveySuccessMsg = "Survey saved successfully";
export const DeleteSurveySuccessMsg = "Survey deleted successfully";
export const DeleteSurveyFormSuccessMsg =
  "Survey question deleted successfully";

export const SaveSuggestionSuccessMsg = "Suggestion saved successfully";
export const DeleteSuggestionSuccessMsg = "Suggestion deleted successfully";
export const DeleteSuggestionFormSuccessMsg =
  "Suggestion question deleted successfully";

export const SuggestionNotificationSuccessMsg = "Suggestion Notification Sent!";

export const SaveFeedbackSuccessMsg = "Feedback saved successfully";
export const DeleteFeedbackSuccessMsg = "Feedback deleted successfully";
export const DeleteFeedbackFormSuccessMsg =
  "Feedback question deleted successfully";

export const assigngrivancessuccessMsg = "Grievance assigned successfully";
export const deassigngrivancessuccessMsg = "Grievance deassigned successfully";
export const closedgrivancessuccessMsg = "Grievance is Closed";
export const holdgrivancessuccessMsg = "Grievance is kept On Hold";

export const eventSavesuccessMsg = "Event saved successfully";
export const thumbnailUploadsuccessMsg = "Thumbnails upload successfully";
export const bannerUploadsuccessMsg = "Banner upload successfully";
export const eventPublishsuccessMsg = "Event published successfully";
export const eventCanceledsuccessMsg = "Event canceled successfully";
export const eventUpdatedsuccessMsg = "Event updated successfully";
export const eventSartEndTimeErrorMsg =
  "Event end time must be greater than the start time";
export const eventEndTimeEmptyErrorMsg = "Must have event end time";
export const eventStartTimeEmptyErrorMsg = "Must have event start time";
export const otpVerificationSuccessMsg = "Otp verified successfully";
export const recoveryPasswordSuccessMsg =
  "Password has been reset successfully";

export const budgetdocUploadsuccessMsg = "Budget Document upload successfully";
export const budgetSubmitsuccessMsg = "Budget Plan Submitted Successfully";
export const budgetupdatesuccessMsg = "Budget Plan Updated Successfully";
export const budgetDraftSavesuccessMsg = "Budget Plan Saved as a Draft";
export const budgetapprovesuccessMsg = "Budget Approved successfully";
export const budgetrejectedsuccessMsg = "Budget is Rejected";
export const deletebudgetsuccessMsg = "Budget Deleted Successfully";

export const NotificationSuccessMsg = "Notification Sent!";

export const blueprintdocUploadsuccessMsg =
  "BluePrint Document upload successfully";
export const blueprintSubmitsuccessMsg =
  "BluePrint Plan Submitted Successfully";
export const blueprintupdatesuccessMsg = "BluePrint Plan Updated Successfully";
export const blueprintDraftSavesuccessMsg = "BluePrint Plan Saved as a Draft";
export const blueprintapprovesuccessMsg = "BluePrint Approved successfully";
export const blueprintrejectedsuccessMsg = "BluePrint is Rejected";
export const deleteblueprintsuccessMsg = "BluePrint Deleted Successfully";

export const finalapprovesuccessMsg = "BluePrint is finally approved ";

// storage Id
export const loginDataStorageKey = "green-space-loggedin-data";
export const menuItemsRightsDetails = "menuItemRights";

// Project Create Message
export const projectStatusErrorMsg =
  "Vendor is mandatory when Save as Initiated ";
export const projectNameErrorMsg = "Enter a valid Name";
export const projectNameErrorMsgForMandatoryFld = "Name is required";
export const projectDescriptionErrorMsg = "Description is required";
export const projectStartDateErrorMsg = "Start Date is required";
export const projectInstitutionErrorMsg = "Institution is required";
export const projectProgressErrorMsg = "Progress is required";
export const projectProgressValidationErrorMsg =
  "Progress should be numeric value";
export const projectProgressRangeValidateErrorMsg =
  "Progress should be range (0 to 100)";

export const successfullySubmitted = "Project Saved Successfully";
export const successfullyUpdated = "Project Updated Successfully";

//enums
export const recordStatusKeys = {
  0: {
    label: "InActive",
  },
  1: {
    label: "Active",
  },
};

export const recordStatusKeysDuplicate = {
  0: {
    label: "Inactive",
  },
  1: {
    label: "Active",
  },
};

export const surveyStatusKeys = {
  1: {
    label: "Pending",
  },
  2: {
    label: "Published",
  },
  3: {
    label: "Expired",
  },
};

export const feedbackStatusKeys = {
  1: {
    label: "Pending",
  },
  2: {
    label: "Published",
  },
};
export const budgetrecordStatusKeys = {
  0: {
    label: "Pending",
  },
  1: {
    label: "Submitted",
  },
  2: {
    label: "Approved",
  },
  3: {
    label: "Rejected",
  },
};

export const eventStatusEnums = {
  published: 1,
  pending: 0,
  canceled: 2,
  delete: 9,
};

// Dropdown payload
const dropdownPaginationFields = {
  searchText: "",
  sortType: "",
  pageNumber: 0,
  rowsOfPage: 1000,
  sortingCol: "",
};

// Dropdown payload
const dropdownFilterFields = {
  searchText: "",
  sortType: "",
  pageNumber: 0,
  rowsOfPage: 1000,
  sortingCol: "",
};

export const grievancecategorypayLoad = {
  ...dropdownFilterFields,
  categoryType: 2,
};

export const categorypayLoadFeedback = {
  ...dropdownFilterFields,
  categoryType: 1,
};

export const subcategorypayLoad = {
  parentId: 0,
  ...dropdownFilterFields,
};

export const getsurveypayLoad = {
  ...dropdownPaginationFields,
  surveyStatus: -1,
  sortingCol: "createdAt",
  sortType: "asc",
};

export const getsuggestionpayLoad = {
  ...dropdownPaginationFields,
  surveyStatus: -1,
};
export const getfeedbackpayLoad = {
  ...dropdownPaginationFields,
  surveyStatus: -1,
};

export const getParkListPayLoad = {
  ...dropdownPaginationFields,
};

export const getVacantPlotListPayLoad = {
  ...dropdownPaginationFields,
};

export const entityTypeDetails = {
  grievance: 2,
  budget_plan: 4,
  blue_print: 5,
};

export const entityTypeEnums = {
  ward: 2,
  park: 3,
  vaccantPlot: 1,
};
export const interVals = [
  {
    tabName: "Upcoming",
    tabId: 1,
  },
  {
    tabName: "Past",
    tabId: 0,
  },
  {
    tabName: "Draft",
    tabId: 2,
  },
];

export const statusEnums = {
  draft: 0,
  initiated: 1,
};

export const defaultPagination = {
  pageNumber: 0,
  rowsOfPage: 10,
};
export const fileSaveEnums = {
  save: 1,
  delete: 9,
};
export const accessModeEnums = {
  web: 1,
};

export const metriceIdList = {
  1: "Cleanliness Rating",
  2: "Maintenance Rating",
  3: "Safety Rating",
  4: "Accesibility Rating",
  5: "Amenities Rating",
  6: "Vegetation Rating",
  7: "Utility Rating",
  8: "Inlusivity Rating",
  9: "Overall Rating",
  10: "Net Promoter Score",
};

const viewAction = createActionIcon("View", "View", "pe-7s-look", "lookModal");

const editAction = createActionIcon("Edit", "Edit", "pe-7s-edit", "editModal");

const feedbackAction = createActionIcon(
  "Feedback",
  "Feedback",
  "pe-7s-note2",
  "feedbackModal"
);

const eventCancellationAction = createActionIcon(
  "eventCancellation",
  "Event Cancellation WIP",
  "pe-7s-close",
  "eventCancellationModal"
);

const publishAction = createActionIcon(
  "Publish",
  "Publish",
  "pe-7s-speaker",
  "publishConfirmationModal"
);

export const upComingEventActionbtnList = [
  viewAction,
  editAction,
  feedbackAction,
  eventCancellationAction,
];
export const pastEventActionbtnList = [feedbackAction];
export const draftEventActionbtnList = [viewAction, editAction, publishAction];

export const statusList = [
  {
    id: 1,
    itemName: "Draft",
    value: 0,
  },
  {
    id: 2,
    itemName: "Initiated",
    value: 1,
  },
];

export const commentsStatusMap = {
  2: "Approved",
  3: "Rejected",
};
