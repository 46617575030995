import config from "../../config/config";
export const BASE_URL = config.baseURL;

const startString = "/api/v1";
const startStringFeedbackSurvey = "/api";
const grievancesStartString = `${startString}/Grievances`;
const geoStartString = `${startString}/Geo`;
const eventsStartString = `${startString}/Events`;
const projectStartString = `${startString}/Project`;
const dashboardStartString = `${startString}/Dashboard`;

export const loginApi = `${startString}/UserAuth/Login`;
export const forgetPasswordApi = `${startString}/UserAuth/ForgetPassword`;
export const validateOtpApi = `${startString}/UserAuth/ValidateWebOtp`;
export const resetWebPasswordApi = `${startString}/UserAuth/ResetWebPassword`;
export const refreshTokenApi = `${startString}/UserAuth/GetRenewedToken`;

// export const getDesignationApi = `${startString}/Common/GetDesignations`;

export const getDesignationApi = (deptId) => {
  return deptId
    ? `${startString}/Common/GetDesignations?id=0&DeptId=${deptId}`
    : `${startString}/Common/GetDesignations?id=0&DeptId=0`;
};

export const saveDesignationApi = `${startString}/Common/SaveDesignations`;

export const getInstitutionApi = `${startString}/Common/GetInstitutions`;

export const getDepartmentApi = (instId) => {
  return instId
    ? `${startString}/Common/GetDepartments?InstId=${instId}`
    : `${startString}/Common/GetDepartments`;
};
export const saveDepartmentsApi = `${startString}/Common/SaveDepartments`;

export const getFeedbackCategoryApi = `${startString}/Common/GetBaseCategories`;
export const getFeedbackSubCategoryApi = `${startString}/Common/GetCategoriesList`;
export const getFeedbackSubCategorybyCategoryApi = `${startString}/Common/GetCategoriesByParent`;

export const getRoleApi = `${startString}/Common/GetRoles`;

// export const getRoleByOrgAPI = `${startString}/Common/GetRolesByOrg`;
export const getRoleByOrgAPI = (orgId) =>
  `${startString}/Common/GetRolesByOrg?OrgId=${orgId}`;

export const getUserListApi = `${startString}/UserDetail/GetUserList`;
export const createUserApi = `${startString}/UserDetail/CreateUser`;
export const updateUserApi = `${startString}/UserDetail/UpdateUser`;
export const getCHEDUserListApi = `${startString}/UserDetail/GetCHEDUsers`;
export const getCHEDSupervisorListApi = `${startString}/UserDetail/GetCHEDSupervisor`;
export const getKUOUserListApi = `${startString}/UserDetail/GetKUOUsers`;

export const getMyRightsApi = `${startString}/UserDetail/GetMyRights`;
export const getRightApi = `${startString}/Common/GetRights`;
export const assignRightApi = `${startString}/Common/AssignRights`;
export const getRolesNRightsApi = (roleId) => {
  return roleId
    ? `${startString}/Common/GetRolesnRights?RoleId=${roleId}`
    : `${startString}/Common/GetRolesnRights`;
};

export const updateRoleAPI = `${startString}/Common/UpdateRoles`;

// For Survey
export const getSurveyApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetSurveyList`;
export const addSurveyApi = `${startStringFeedbackSurvey}/SurveyFeedback/AddSurvey`;
export const updateSurveyApi = `${startStringFeedbackSurvey}/SurveyFeedback/UpdateSurvey`;

// For Suggestion
export const getSuggestionApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetSuggestionList`;
export const addSuggestionApi = `${startStringFeedbackSurvey}/SurveyFeedback/AddSuggestion`;
export const saveSuggestionApi = `${startStringFeedbackSurvey}/SurveyFeedback/SaveSuggestionQA`;
export const updateSuggestionApi = `${startStringFeedbackSurvey}/SurveyFeedback/UpdateSuggestion`;
export const getSuggestionDetailsApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetAllSuggestionQA`;
export const SuggestionAnalysisApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetSuggestionAnalysis`;

//forsurvey QA
export const saveSurveyApi = `${startStringFeedbackSurvey}/SurveyFeedback/SaveSurveyQA`;

// For Feedback
export const getFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetFeedbackList`;
export const addFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/AddFeedback`;
export const updateFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/UpdateFeedback`;
export const getExportSurveyFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/ExportSurveyFeed`;

//for feedback QA
export const saveFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/SaveFeedbackQA`;
export const getSurveyDetailsApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetAllSurveyQA`;
export const getFeedbackDetailsApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetAllFeedQA`;
export const publishFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/PublishFeedback`;
export const unpublishFeedbackApi = `${startStringFeedbackSurvey}/SurveyFeedback/UnpublishFeedback`;
export const FeedbackAnalysisApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetFeedbackAnalysis`;
export const SurveyAnalysisApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetSurveyAnalysis`;
export const feedbackSurveyCommentsApi = `${startStringFeedbackSurvey}/SurveyFeedback/GetFeedSurveyComments`;

//For Grievances
export const getGrievancesApi = `${startString}/Grievances/GetAllGrievances`;
export const getGrievancesFilesApi = `${startString}/Grievances/GetGrievancesFiles`;
export const assignGrievanceApi = `${startString}/Grievances/AssignGrievance`;
export const deassignGrievanceApi = `${startString}/Grievances/DeAssignGrievance`;
export const getAllGrevaincesApi = `${grievancesStartString}/GetAllGrievances`;
export const getGrievancesCommentsApi = `${grievancesStartString}/GetGrievanceComment`;
export const getExportGrievanceApi = `${grievancesStartString}/ExportGrievance`;
export const getAssignedGrievancesApi = `${startString}/Grievances/GetAssignedGrievances`;
export const holdGrievanceApi = `${startString}/Grievances/HoldGrievance`;
export const closeGrievanceApi = `${startString}/Grievances/CloseGrievance`;
export const getGrievancesSummeryApi = `${dashboardStartString}/GetGrievanceSummary`;
export const getGrievancesInfoApi = `${dashboardStartString}/GetGrievanceInfo`;

// For Parks
export const getAllParksApi = `${geoStartString}/GetParkList`;
export const getParkDetailsApi = `${geoStartString}/GetParkDetail`;
export const getParkInfoApi = `${dashboardStartString}/GetParkInfo`;
export const getParkListByEventsApi = `${geoStartString}/GetParkListByEvents`;

// For Vacant Plot
export const getAllVacantPlotApi = `${geoStartString}/GetVacantPlotList`;
export const getVacantPlotDetailsApi = `${geoStartString}/GetVacantPlotDetail`;

// For map tabs
export const getNearByATMApi = `${geoStartString}/GetNearByATM`;
export const getNearByFireStationApi = `${geoStartString}/GetNearByFireStation`;
export const getNearByBusStopApi = `${geoStartString}/GetNearByBusStop`;
export const getNearAutoTaxiStandApi = `${geoStartString}/GetNearAutoTaxiStand`;
export const getNearCellTowersApi = `${geoStartString}/GetNearCellTowers`;
export const getNearStreetLightApi = `${geoStartString}/GetNearStreetLight`;
export const getNearTransformerApi = `${geoStartString}/GetNearTransformer`;
export const getNearBridgeFlyOversApi = `${geoStartString}/GetNearBridgeFlyOvers`;
export const getNearSubstationApi = `${geoStartString}/GetNearSubstation`;
export const getNearRoadNetworkApi = `${geoStartString}/GetNearRoadNetwork`;
export const getNearByCommunityToiletApi = `${geoStartString}/GetNearByToilet`;
export const getNearByMetroStationApi = `${geoStartString}/GetNearByMetroStation`;
export const getNearByRailWayStationApi = `${geoStartString}/GetNearByRailWayStation`;
export const getNearByDrainageApi = `${geoStartString}/GetNearByDrainage`;
//
export const getNearByWaterSupplyApi = `${geoStartString}/GetNearByWaterSupply`;
export const getNearByWaterBodyApi = `${geoStartString}/GetNearByWaterBody`;
export const getNearByWaterMetroApi = `${geoStartString}/GetNearByWaterMetro`;

// For Events
export const uploadEventThumbnailApi = `${eventsStartString}/UploadEventThumbnail`;
export const uploadBannerApi = `${eventsStartString}/UploadEventBanners`;
export const saveEventsApi = `${eventsStartString}/SaveEvents`;
export const getAllEventsApi = `${eventsStartString}/GetAllEvents`;
export const getEventFilesApi = `${eventsStartString}/GetEventFilesById`;
export const getEventLikeFeedApi = (eventId) =>
  `${eventsStartString}/GetEventLikedFeed?EventId=${eventId}`;
export const getEventCommentFeedApi = `${eventsStartString}/GetEventCommentFeed`;
export const getCancelEventsApi = `${eventsStartString}/CancelEvents`;

//Notification send in event
export const sentSuggestionNotificationApi = `${startStringFeedbackSurvey}/SurveyFeedback/SendSuggestionNotification`;

//For Rating
export const getCategoryRatingApi = `${dashboardStartString}/GetDashboardRatings`;
export const getDasboardChartApi = `${dashboardStartString}/GetDashboardStats`;

// For getting uploaded files
export const getUploadedFilesApi = (EntityType, FileName, FilePath) => {
  const params = new URLSearchParams();

  if (EntityType) {
    params.append("EntityType", EntityType);
  }
  if (FileName) {
    params.append("Filename", FileName);
  }
  if (FilePath) {
    params.append("FilePath", FilePath);
  }

  return `${startString}/Common/GetUploadedItem?${params.toString()}`;
};

// For Wards
export const getAllWardsApi = `${geoStartString}/GetWardList`;
export const getWardDetailsApi = `${geoStartString}/GetWardDetail`;

// For Manage Project

// For Budgets
export const getAllBudgetsApi = `${projectStartString}/GetAllBudgets`;
export const getBudgetDocApi = (BudgetId) => {
  return `${projectStartString}/GetBudgetDocs?BudgetId=${BudgetId}`;
};
export const uploadBudgetDocApi = `${projectStartString}/UploadBudgetDoc`;
export const saveBudgetRequestApi = `${projectStartString}/AddBudgetPlan`;
export const updateBudgetRequestApi = `${projectStartString}/UpdateBudgetPlan`;
export const getPendingBudgetsApi = `${projectStartString}/GetPendingBudgets`;
export const getApprRejectBudgetsApi = `${projectStartString}/GetApprRejectedBudgets`;
export const approveBudgetsApi = `${projectStartString}/ApproveBudgetPlan`;
export const rejectBudgetsApi = `${projectStartString}/RejectBudgetPlan`;
export const deleteBudgetPlanApi = `${projectStartString}/DeleteBudgetPlan`;
export const getBudgetCommentsApi = (ApprStatus, Id) => {
  return `${projectStartString}/GetBudgetPlanHistory?ApprStatus=${ApprStatus}&Id=${Id}`;
};

//Notification send
export const sentNotificationApi = `${projectStartString}/SendNotificationRequest`;

// For Blue Prints
export const getAllBluePrintsApi = `${projectStartString}/GetAllBluePrints`;
export const getBluePrintDocApi = (BluePrintId) => {
  return `${projectStartString}/GetBluePrintDocs?BluePrintId=${BluePrintId}`;
};
export const uploadBluePrintDocApi = `${projectStartString}/UploadBluePrintDoc`;
export const saveBluePrintRequestApi = `${projectStartString}/AddBluePrintPlan`;
export const updateBluePrintRequestApi = `${projectStartString}/UpdateBluePrintPlan`;
export const getPendingBluePrintsApi = `${projectStartString}/GetPendingBluePrints`;
export const getApprRejectBluePrintsApi = `${projectStartString}/GetApprRejectBluePrints`;
export const approveBluePrintsApi = `${projectStartString}/ApproveBluePrintPlan`;
export const rejectBluePrintsApi = `${projectStartString}/RejectBluePrintPlan`;
export const getBluePrintCommentsApi = (ApprStatus, Id) => {
  return `${projectStartString}/GetBluePlanHistory?ApprStatus=${ApprStatus}&Id=${Id}`;
};

// For Final Approve Plans
export const getPendingFinalPlanApi = `${projectStartString}/GetFinalPendingPlans`;
export const getApprovedFinalPlanApi = `${projectStartString}/GetFinalApprPlans`;
export const approveFinalPlanApi = `${projectStartString}/FinalApprove`;

// For Project List and Create Project
export const createProjectApi = `${projectStartString}/CreateProject`;
export const updateProjectApi = `${projectStartString}/UpdateProject`;
export const getProjectDataApi = `${projectStartString}/GetProjects`;
export const getExportProjects = `${projectStartString}/ExportProjects`;
