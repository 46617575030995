export const MenuItems = {
  Dashboard: {
    icon: "pe-7s-home",
    accessName: "Dashboard",
    label: "Dashboard",
    to: "#/dashboards/crm",
    content: [],
  },

  "Ward Dashboard": {
    icon: "pe-7s-graph2",
    accessName: "Ward Dashboard",
    label: "Ward Dashboard",
    to: "#/dashboards/ward",
    content: [],
  },

  "Park Dashboard": {
    icon: "pe-7s-photo",
    accessName: "Park Dashboard",
    label: "Park Dashboard",
    to: "#/dashboards/park",
    content: [],
  },

  "Manage Community Events": {
    icon: "pe-7s-leaf",
    accessName: "Manage Community Events",
    label: "Manage Community Events",
    to: "#/dashboards/communityevents",
    content: [],
  },

  "Manage Project": {
    icon: "pe-7s-monitor",
    accessName: "Manage Project",
    label: "Manage Project",
    to: "#/manageproject",
    content: [],
  },

  "Budget Requests": {
    icon: "pe-7s-tools",
    accessName: "Budget Requests",
    label: "Budget Requests",
    to: "#/manageproject/budgetrequests",
    content: [],
  },

  "Blueprint Requests": {
    icon: "pe-7s-tools",
    accessName: "Blueprint Requests",
    label: "Blueprint Requests",
    to: "#/manageproject/blueprintrequests",
    content: [],
  },

  "Budget Approval": {
    icon: "pe-7s-tools",
    accessName: "Budget Approval",
    label: "Budget Approval",
    to: "#/manageproject/budgetapprove",
    content: [],
  },

  "Blueprint Approval": {
    icon: "pe-7s-tools",
    accessName: "Blueprint Approval",
    label: "Blueprint Approval",
    to: "#/manageproject/blueprintapprove",
    content: [],
  },

  "Final Approval": {
    icon: "pe-7s-tools",
    accessName: "Final Approval",
    label: "Final Approval",
    to: "#/manageproject/finalapprove",
    content: [],
  },
  Projects: {
    icon: "pe-7s-tools",
    accessName: "Projects",
    label: "Project Listing",
    to: "#/manageproject/projectlist",
    content: [],
  },

  Grievances: {
    icon: "pe-7s-note2",
    accessName: "Grievances",
    label: "Maintenance & Service",
    to: "#/grievance/details",
    content: [],
  },

  "Manage Feedback / Survey": {
    icon: "pe-7s-notebook",
    accessName: "Manage Feedback / Survey",
    label: "Feedback / Survey",
    to: "#/feedbacksurvey",
    content: [],
  },

  "Feedback / Survey": {
    icon: "pe-7s-tools",
    accessName: "Feedback / Survey",
    label: "Create / Modify ",
    to: "#/feedbacksurvey/create",
    content: [],
  },

  Publish: {
    icon: "pe-7s-tools",
    accessName: "Publish",
    label: "Publish ",
    to: "#/feedbacksurvey/publish",
    content: [],
  },

  "Feedback Response": {
    icon: "pe-7s-tools",
    accessName: "Feedback Response",
    label: "Feedback Response",
    to: "#/feedbacksurvey/viewfeedbackdetails",
    content: [],
  },

  "Survey Response": {
    icon: "pe-7s-tools",
    accessName: "Survey Response",
    label: "Survey/Suggestion Response",
    to: "#/feedbacksurvey/viewsurveysuggestiondetails",
    content: [],
  },

  "User Management": {
    icon: "pe-7s-config",
    accessName: "User Management",
    label: "User Management",
    to: "#/usermanagement",
    content: [],
  },

  Roles: {
    icon: "pe-7s-tools",
    accessName: "Roles",
    label: "Roles",
    to: "#/usermanagement/roles",
    content: [],
  },

  Users: {
    icon: "pe-7s-tools",
    accessName: "Users",
    label: "Users",
    to: "#/usermanagement/users",
    content: [],
  },

  Configurations: {
    icon: "pe-7s-config",
    accessName: "Configurations",
    label: "Configurations",
    to: "#/configuration",
    content: [],
  },

  Department: {
    icon: "pe-7s-tools",
    accessName: "Department",
    label: "Department",
    to: "#/configuration/master/department",
    content: [],
  },
  Designation: {
    icon: "pe-7s-tools",
    accessName: "Designation",
    label: "Designation",
    to: "#/configuration/master/designation",
    content: [],
  },
};
