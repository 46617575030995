export const ReturnStringTime = (time) => {
  let hours = time.getHours();
  const min = time.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${hours.toString().padStart(2, "0")}:${min
    .toString()
    .padStart(2, "0")} ${ampm}`;
};
