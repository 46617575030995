import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const RoleRightsSlice = createSlice({
  name: "RoleRightsSlice",
  initialState: initialState,
  reducers: {
    storeRoleRightsSlice(state, action) {
      const { data } = action.payload;
      state.data = data;
    },
    removeRoleRightsSlice() {
      return initialState;
    },
  },
});

export default RoleRightsSlice.reducer;
export const { storeRoleRightsSlice, removeRoleRightsSlice } =
  RoleRightsSlice.actions;
