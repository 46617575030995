import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useRouteTracker = () => {
  const [routeHistory, setRouteHistory] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setRouteHistory((prevRoutes) => [...prevRoutes, location.pathname]);
  }, [location]);

  return routeHistory;
};
