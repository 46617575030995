const config = {
  development: {
    baseURL: "https://greenspacedev.elogixsoft.com/greenspace.api",
    REACT_APP_FILE_BASE_PATH:
      "https://greenspacedev.elogixsoft.com/greenspace.image.dev/images",
    debug: true,
  },

  production: {
    baseURL: "https://greenopenspaces.elogixsoft.com/greenspace.api",
    REACT_APP_FILE_BASE_PATH:
      "https://greenopenspaces.elogixsoft.com/greenspace.image/images",
    debug: false,
  },
  test: {
    baseURL: "https://greenspacedev.elogixsoft.com/greenspace.api",
    debug: true,
  },
};

export default config.production;
