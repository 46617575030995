// import "./polyfills";
import React from "react";
import { createRoot } from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import { Provider } from "react-redux";
import setupStore, { persistor } from "config/configureStore";
import ServerRequiestWrapper from "server/utils/server-requiest";
import AppWrapper from "app-wrapper";
import { PersistGate } from "redux-persist/lib/integration/react";


const store = setupStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        {AppWrapper(Component)}
        {/* <Component /> */}
      </HashRouter>
    </PersistGate>
  </Provider>
);

const root = createRoot(rootElement).render(renderApp(Main));

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    root.render(renderApp(NextApp));
  });
}