import moment from "moment-timezone";

// const momentLocal = (dateTime) => moment.utc(dateTime).local("en-in");
const momentLocal = (dateTime) => moment(dateTime); // Directly use the local time

// todo: UTC to local date time conversion to be further worked on
// export const DateTimeStringFormat = (dateTime, formatType = "payload") => {
//   if (formatType === "display") {
//     console.log("DATE TIME EVENT FUNC: ", dateTime);
//     return dateTime && momentLocal(dateTime).format("DD/MM/YYYY");
//   }
//   return dateTime && momentLocal(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
// };

// Date-time format function
export const DateTimeStringFormat = (dateTime, formatType = "payload") => {
  if (formatType === "display") {
    // console.log("DATE TIME EVENT FUNC: ", dateTime);
    return dateTime && momentLocal(dateTime).format("DD/MM/YYYY"); // Display format
  }
  // Default: Return format for payload with milliseconds
  return dateTime && momentLocal(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

// export const DateTimeStringFormatForProject = (
//   dateTime,
//   formatType = "payload"
// ) => {
//   if (formatType === "display") {
//     return dateTime && moment(dateTime).format("DD/MM/YYYY");
//   }
//   return dateTime && moment(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
// };

export const DateStringFormat = (dateTime) => {
  return (
    dateTime &&
    moment(dateTime).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  );
};

export const DateTimeStringFormatDDMMYYYY = (dateTime) => {
  return dateTime && moment(dateTime).format("DD/MM/YYYY");
};

export const DateStringToWordFormat = (dateTime) => {
  return dateTime && moment(dateTime).format("Do MMM , YYYY");
};

export const convertTo12HourFormat = (dateTimeStr) => {
  const date = new Date(dateTimeStr);

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  const timeString = date.toLocaleTimeString("en-US", options);

  return timeString;
};

export const TimeFormat = (dateTime) => {
  const date = new Date(dateTime);
  return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
};
export const ParseTimeString = (timeString) => {
  const [time, period] = timeString.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const formatDateForDisplay = (date) => {
  const d = new Date(date);
  const day = `0${d.getDate()}`.slice(-2);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};
