const initialState = {
  instituteDataList: [],
  categoryDataList: [],
  grievancecategoryDataList: [],
  subCategoryDataList: [],
  grievancesubCategoryDataList: [],
  roleDataList: [],
  rightDataList: [],
  designationList: [],
  surveyList: [],
  feedbackList: [],
  suggestionList: [],
  parkDropdownList: [],
  vacantplotDropdownList: [],
  CHEDuserList: [],
  CHEDsupervisorList: [],
  KUOuserList: [],
};

const DropDownSlicer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DROPDOWN_LISTS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default DropDownSlicer;
