import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const CurrentPageDataSlice = createSlice({
  name: "CurrentPageDataSlice",
  initialState: initialState,
  reducers: {
    storeCurrentPageData(state, action) {
      const { data } = action.payload;
      state.data = data;
    },
    removeCurrentPageData() {
      return initialState;
    },
  },
});

export default CurrentPageDataSlice.reducer;
export const { storeCurrentPageData, removeCurrentPageData } = CurrentPageDataSlice.actions;
