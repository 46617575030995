import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import cx from "classnames";
import AppMain from "Layout/AppMain";
const Main = () => {
  const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = useSelector((state) => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  }));

  // const sidebarHoverClass = enableClosedSidebar
  //   ? "collapsed-sidebar-hover"
  //   : "";

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1250 },
              {
                "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
              },
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
              //sidebarHoverClass
            )}
          >
            <AppMain />
          </div>
        </Fragment>
      )}
    />
  );
};

export default withRouter(Main);
