import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "Components/PrivateRoute";

const UserPages = lazy(() => import("../../Pages/UserPages"));
const Dashboards = lazy(() => import("../../Pages/Dashboards"));
const Configuration = lazy(() => import("../../Pages/Configuration"));
const ManageProject = lazy(() => import("../../Pages/ManageProject"));
const UserManagement = lazy(() => import("../../Pages/UserManagement"));
const Grievances = lazy(() => import("../../Pages/Grievances"));
const FeedbackSurvey = lazy(() => import("../../Pages/FeedbackSurvey"));

const AppMain = () => {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
              <h6 className="mt-3">Please wait while we load all the Pages</h6>
            </div>
          </div>
        }
      >
        <Route path="/pages" component={UserPages} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Dashboards.
              </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/dashboards" component={Dashboards} />
        <PrivateRoute path="/configuration" component={Configuration} />
        <PrivateRoute path="/manageproject" component={ManageProject} />
        <PrivateRoute path="/usermanagement" component={UserManagement} />
        <PrivateRoute path="/grievance" component={Grievances} />
        <PrivateRoute path="/feedbacksurvey" component={FeedbackSurvey} />
      </Suspense>
      <Route exact path="/" render={() => <Redirect to="/pages/login" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
