import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const LoginDataSlice = createSlice({
  name: "LoginDataSlice",
  initialState: initialState,
  reducers: {
    storeLoginData(state, action) {
      const { data } = action.payload;
      state.data = data;
    },
    removeLoginData() {
      return initialState;
    },
  },
});

export default LoginDataSlice.reducer;
export const { storeLoginData, removeLoginData } = LoginDataSlice.actions;
