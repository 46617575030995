import config from "config/config";

const ProcessEventBannerResponse = (data) => {
  const IMAG_BASE_URL = config.REACT_APP_FILE_BASE_PATH;

  let listNames = [];
  let _eventFiles = [];
  let fileNameList = [];
  data.forEach((file) => {
    const { filePath, fileName, id } = file;
    let _filePath = filePath.replace(/\//g, "\\");
    listNames = [
      ...listNames,
      { filePath: `${IMAG_BASE_URL}${_filePath}/${fileName}`, id: id },
    ];
    _eventFiles = [..._eventFiles, file];
    fileNameList = [...fileNameList, fileName];
  });
  return {
    _eventFiles: _eventFiles,
    fileNameList: fileNameList,
    listNames: listNames,
  };
};

export default ProcessEventBannerResponse;
