import Apislice from "./Apislice";
import LoginDataSlice from "./LoginDataSlice";
import DropDownSlicer from "./DropDownslice";
import ThemeOptions from "./ThemeOptions";

import { combineReducers } from "redux";
import MenuSlice from "./MenuSlice";
import RoleRightsSlice from "./RoleRightsSlice";
import CurrentPageDataSlice from "./CurrentPageDataSlice";

const reducer = combineReducers({
  ThemeOptions,
  Apislice,
  LoginDataSlice,
  DropDownSlicer,
  MenuSlice,
  RoleRightsSlice,
  CurrentPageDataSlice
});

export default reducer;
