export const validationsCheck = (arrvalue, validation_type) => {
  switch (validation_type) {
    case "userName":
      return /^[a-zA-Z0-9._-]{4,13}$/.test(arrvalue);
    case "name":
      return /^.{3,50}$/.test(arrvalue);
    case "email":
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(arrvalue);
    case "password":
      return /.{8,}$/.test(arrvalue);
    case "name_space":
      return /^[a-zA-Z ]{3,50}$/.test(arrvalue);
    case "deptcode":
      return /^[a-zA-Z0-9]+$/.test(arrvalue);
    case "phnNo":
      return /^[0-9]{10}$/.test(arrvalue);
    case "projectName":
      return /^[A-Za-z\s'_-]+$/.test(arrvalue);
    case "progressPerc":
      return /^-?\d+(\.\d+)?$/.test(arrvalue);
    case "number":
      return /^[0-9]+$/.test(arrvalue);
    default:
      return true;
  }
};
