import { createSlice } from "@reduxjs/toolkit";

const ApiSlice = createSlice({
  name: "ApiSlice",
  initialState: {},
  reducers: {
    storeApi(state, action) {
      const { data } = action.payload;

      state.data = data;
    },
    removeApi(state, action) {
      const id = action.payload;
      delete state[id];
    },
  },
});

export default ApiSlice.reducer;
export const { storeApi, removeApi } = ApiSlice.actions;
