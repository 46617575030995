import config from "config/config";
import { ParseTimeString } from "./date-format";

export const setEventFormOnLoad = (selectedEvent) => {
  let _formData;
  let _thumbleSrc;
  let _eventStartTime;
  let _eventEndTime;
  const IMAG_BASE_URL = config.REACT_APP_FILE_BASE_PATH;
  const [eventStartTime, eventEndTime] = selectedEvent.eventTiming.split(" - ");
  _eventStartTime = ParseTimeString(eventStartTime);
  _eventEndTime = ParseTimeString(eventEndTime);

  _thumbleSrc = `${IMAG_BASE_URL}${selectedEvent.thumbnail}`;

  selectedEvent = {
    ...selectedEvent,
    eventFiles: [],
  };

  _formData = { ...selectedEvent };

  return {
    formData: _formData,
    thumbleSrc: _thumbleSrc,
    eventStartTime: _eventStartTime,
    eventEndTime: _eventEndTime,
  };
};
