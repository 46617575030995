export const createMarkerInfo = (markerInfoObj) => {
    let markerInfo = '';

    if (markerInfoObj?.roadName) {
      markerInfo += `${markerInfoObj?.roadName}, `;
    }
    if (markerInfoObj?.parkName) {
      markerInfo += `${markerInfoObj?.parkName}, `;
    }
    if (markerInfoObj?.wardNo) {
      markerInfo += `Ward No. ${markerInfoObj?.wardNo}, `;
    }
    if (markerInfoObj?.locality) {
      markerInfo += `${markerInfoObj?.locality}`;
    }
    markerInfo = markerInfo.trim().replace(/,$/, '');
    return markerInfo;
  };