import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import {
  getDesignationApi,
  getFeedbackCategoryApi,
  getFeedbackSubCategoryApi,
  getFeedbackSubCategorybyCategoryApi,
  loginApi,
  getInstitutionApi,
  getDepartmentApi,
  getRoleApi,
  getUserListApi,
  getRightApi,
  assignRightApi,
  getRolesNRightsApi,
  createUserApi,
  updateUserApi,
  getCHEDUserListApi,
  getCHEDSupervisorListApi,
  getKUOUserListApi,
  getSurveyApi,
  addSurveyApi,
  saveSurveyApi,
  getFeedbackApi,
  addFeedbackApi,
  saveFeedbackApi,
  getSuggestionApi,
  addSuggestionApi,
  saveSuggestionApi,
  SuggestionAnalysisApi,
  sentSuggestionNotificationApi,
  updateSuggestionApi,
  getSurveyDetailsApi,
  getSuggestionDetailsApi,
  updateSurveyApi,
  updateFeedbackApi,
  getFeedbackDetailsApi,
  getGrievancesApi,
  getAssignedGrievancesApi,
  getGrievancesFilesApi,
  getUploadedFilesApi,
  assignGrievanceApi,
  closeGrievanceApi,
  holdGrievanceApi,
  deassignGrievanceApi,
  getGrievancesCommentsApi,
  getAllGrevaincesApi,
  getAllParksApi,
  getParkDetailsApi,
  getAllVacantPlotApi,
  getVacantPlotDetailsApi,
  getAllWardsApi,
  getWardDetailsApi,
  uploadEventThumbnailApi,
  uploadBannerApi,
  saveEventsApi,
  getAllEventsApi,
  getEventFilesApi,
  publishFeedbackApi,
  unpublishFeedbackApi,
  getAllBudgetsApi,
  getBudgetDocApi,
  uploadBudgetDocApi,
  saveBudgetRequestApi,
  updateBudgetRequestApi,
  getPendingBudgetsApi,
  getApprRejectBudgetsApi,
  approveBudgetsApi,
  rejectBudgetsApi,
  deleteBudgetPlanApi,
  getBudgetCommentsApi,
  sentNotificationApi,
  getAllBluePrintsApi,
  getBluePrintDocApi,
  uploadBluePrintDocApi,
  saveBluePrintRequestApi,
  updateBluePrintRequestApi,
  getPendingBluePrintsApi,
  getApprRejectBluePrintsApi,
  approveBluePrintsApi,
  rejectBluePrintsApi,
  getBluePrintCommentsApi,
  getPendingFinalPlanApi,
  getApprovedFinalPlanApi,
  approveFinalPlanApi,
  getNearByATMApi,
  getNearByBusStopApi,
  getNearByDrainageApi,
  getNearByFireStationApi,
  getNearByRailWayStationApi,
  getNearByMetroStationApi,
  getNearByCommunityToiletApi,
  getNearByWaterSupplyApi,
  getNearByWaterBodyApi,
  getNearByWaterMetroApi,
  FeedbackAnalysisApi,
  SurveyAnalysisApi,
  getMyRightsApi,
  getParkInfoApi,
  getGrievancesSummeryApi,
  getGrievancesInfoApi,
  getExportGrievanceApi,
  getCategoryRatingApi,
  getDasboardChartApi,
  getParkListByEventsApi,
  getNearAutoTaxiStandApi,
  getNearCellTowersApi,
  getNearStreetLightApi,
  getNearTransformerApi,
  getNearSubstationApi,
  getNearRoadNetworkApi,
  getNearBridgeFlyOversApi,
  getEventLikeFeedApi,
  getEventCommentFeedApi,
  getCancelEventsApi,
  createProjectApi,
  updateProjectApi,
  getProjectDataApi,
  getRoleByOrgAPI,
  feedbackSurveyCommentsApi,
  updateRoleAPI,
  forgetPasswordApi,
  validateOtpApi,
  resetWebPasswordApi,
  getExportSurveyFeedbackApi,
  getExportProjects,
} from "server/utils/apis";
import { saveDesignationApi } from "server/utils/apis";
import { saveDepartmentsApi } from "server/utils/apis";
import ServerRequiestWrapper from "server/utils/server-requiest";
import {
  // getLocalStorageItem,
  GlobalContext,
  hasValue,
  validationsCheck,
} from "shared";
import AppToast from "shared/associate/app-toast";
import {
  deptErrMsg,
  emailErrMsg,
  loginDataStorageKey,
  loginSuccessMsg,
  nameErrMsg,
  passwordErrMsg,
  phnNoErrMsg,
  codeErrMsg,
  useNameErrMsg,
  instituteErrMsg,
  deptnameErrMsg,
  designationErrMsg,
  desigCodeErrMsg,
  departmentCodeRequiredErrMsg,
  institutionErrMsg,
  useTypeErrMsg,
  roleErrMsg,
  useNameEmptyMsg,
  eventSavesuccessMsg,
  thumbnailUploadsuccessMsg,
  bannerUploadsuccessMsg,
  budgetdocUploadsuccessMsg,
  blueprintdocUploadsuccessMsg,
  projectStatusErrorMsg,
  projectNameErrorMsg,
  projectNameErrorMsgForMandatoryFld,
  projectDescriptionErrorMsg,
  projectStartDateErrorMsg,
  projectInstitutionErrorMsg,
  projectProgressErrorMsg,
  projectDateRangeValidateErrorMsg,
  projectProgressRangeValidateErrorMsg,
  projectProgressValidationErrorMsg,
  otpVerificationSuccessMsg,
  recoveryPasswordSuccessMsg,
} from "shared/constants";

import {
  getsurveypayLoad,
  getsuggestionpayLoad,
  getfeedbackpayLoad,
  getParkListPayLoad,
  getVacantPlotListPayLoad,
  // categorypayLoad,
  grievancecategorypayLoad,
  getallBudgetPayload,
  subcategorypayLoad,
  categorypayLoadFeedback,
} from "shared/constants";

import { storeLoginData } from "slices/LoginDataSlice";
import { hasZeroValue } from "shared/associate/check-zero-value";
import { statusEnums } from "shared/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "store/store";
import { MenuItems } from "Layout/AppNav/AllMenuItems";
import { storeMenuSlice } from "slices/MenuSlice";
import { storeRoleRightsSlice } from "slices/RoleRightsSlice";
// import { removeUserLoginData, storeUserLoginData } from "slices/UserLoginDataSlice";
// import { persistor } from "store/store";

const AppWrapper = (App) => {
  const Api = (props) => {
    const { MethodCall, MethodCallBlob } = props;
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const loggedinInfoRedux = useSelector((state) => state.LoginDataSlice);

    //const loggedinInfo = getLocalStorageItem(loginDataStorageKey);
    const [designationList, setDesignationList] = useState([]);
    // const previousRoute = usePreviousRoute();
    const [previousRoute, setPreviousRoute] = useState();

    const [previousRoutes, setPreviousRoutes] = useState([]);

    // const userRoleId = loggedinInfo?.roleId

    // useEffect(() => {
    //   if (loggedinInfo && Object.keys(loggedinInfoRedux).length === 0) {
    //     dispatch(storeLoginData({ data: loggedinInfo }));
    //   }
    // }, [loggedinInfoRedux]);

    const userRoleId = loggedinInfoRedux?.data?.roleId;

    const userTypeId = loggedinInfoRedux?.data?.userTypeId;

    const postLoginData = async (payLoad) => {
      const { username, password } = payLoad;
      if (!validationsCheck(username, "userName")) {
        AppToast.error(useNameErrMsg);
        return;
      }
      if (!validationsCheck(password, "password")) {
        AppToast.error(passwordErrMsg);
        return;
      }
      return await MethodCall(loginApi, "POST", payLoad, loginSuccessMsg);
    };
    const postForgetPassword = async (payLoad) => {
      const { userName } = payLoad;
      if (!validationsCheck(userName, "userName")) {
        AppToast.error(useNameErrMsg);
        return;
      }
      // if (!validationsCheck(password, "password")) {
      //   AppToast.error(passwordErrMsg);
      //   return;
      // }
      return await MethodCall(forgetPasswordApi, "POST", payLoad);
    };

    //console.log("log the private rought for app-wrapper", loggedinInfoRedux?.data?.accessToken);

    const validateOtp = async (payLoad) => {
      return await MethodCall(
        validateOtpApi,
        "POST",
        payLoad,
        otpVerificationSuccessMsg
      );
    };

    const resetWebPassword = async (payLoad) => {
      const { password } = payLoad;

      if (!validationsCheck(password, "password")) {
        AppToast.error(passwordErrMsg);
        return;
      }
      return await MethodCall(
        resetWebPasswordApi,
        "POST",
        payLoad,
        recoveryPasswordSuccessMsg
      );
    };

    const postDepartmentData = async (savedeptgpayload, deptsuccessMsg) => {
      const { deptCode, deptName, instituteId } = savedeptgpayload;
      if (!hasValue(deptName)) {
        AppToast.error(deptnameErrMsg);
        return;
      }
      if (!hasValue(deptCode)) {
        AppToast.error(departmentCodeRequiredErrMsg);
        return;
      }
      // if (!validationsCheck(deptCode, "deptcode")) {
      //   AppToast.error(codeErrMsg);
      //   return
      // }
      if (!hasValue(instituteId)) {
        AppToast.error(instituteErrMsg);
        return;
      }

      return await MethodCall(
        saveDepartmentsApi,
        "POST",
        savedeptgpayload,
        deptsuccessMsg
      );
    };

    const getSurveyDataNew = async (payLoad) => {
      return await MethodCall(getSurveyApi, "POST", payLoad);
    };

    const getSuggestionDataNew = async (payLoad) => {
      return await MethodCall(getSuggestionApi, "POST", payLoad);
    };

    const getFeedbackData = async (payload) => {
      return await MethodCall(getFeedbackApi, "POST", payload);
    };

    const publishFeedbackData = async (payload, msg) => {
      return await MethodCall(publishFeedbackApi, "POST", payload, msg);
    };
    const unpublishFeedbackData = async (payload, msg) => {
      return await MethodCall(unpublishFeedbackApi, "POST", payload, msg);
    };
    const getParksData = async (payload) => {
      return await MethodCall(getAllParksApi, "POST", payload);
    };
    const getParkListByEvents = async (payload) => {
      return await MethodCall(getParkListByEventsApi, "POST", payload);
    };
    const getParkDetailsData = async (payload) => {
      return await MethodCall(getParkDetailsApi, "POST", payload);
    };

    const getVacantPlotsData = async (payload) => {
      return await MethodCall(getAllVacantPlotApi, "POST", payload);
    };

    const getVacantPlotDetailsData = async (payload) => {
      return await MethodCall(getVacantPlotDetailsApi, "POST", payload);
    };

    const getEventFilesData = async (payload) => {
      return await MethodCall(getEventFilesApi, "POST", payload);
    };

    const getWardsData = async (payload) => {
      return await MethodCall(getAllWardsApi, "POST", payload);
    };

    const getWardDetailsData = async (payload) => {
      return await MethodCall(getWardDetailsApi, "POST", payload);
    };

    const uploadEventThumbnail = async (payload) => {
      const response = await MethodCall(
        uploadEventThumbnailApi,
        "POST",
        payload,
        null,
        true
      );
      return response;
    };

    const uploadBanner = async (payload) => {
      return await MethodCall(uploadBannerApi, "POST", payload, null, true);
    };

    const saveEvents = async (payload, successMsg) => {
      const {
        eventTitle,
        eventDesc,
        venueDetail,
        eventTiming,
        fromDate,
        toDate,
        validTill,
        thumbnail,
        eventFiles,
      } = payload;

      if (!hasValue(eventTitle)) {
        AppToast.error("Must have event title");
        return;
      }
      if (!hasValue(eventDesc)) {
        AppToast.error("Must have event description");
        return;
      }
      if (!hasValue(eventDesc)) {
        AppToast.error("Must have event description");
        return;
      }
      if (!hasValue(venueDetail)) {
        AppToast.error("Must have Venu details");
        return;
      }
      if (!hasValue(thumbnail)) {
        AppToast.error("Must have a thumbnail");
        return;
      }
      if (eventFiles.listNames === 0) {
        AppToast.error("Must have a atleast one banner");
        return;
      }
      if (
        !hasValue(eventTiming) ||
        !hasValue(fromDate) ||
        !hasValue(toDate) ||
        !hasValue(validTill)
      ) {
        AppToast.error("Must set event timming and dates");
        return;
      }
      return await MethodCall(saveEventsApi, "POST", payload, successMsg);
    };

    const getAllEvents = async (payload) => {
      return await MethodCall(getAllEventsApi, "POST", payload);
    };
    const getEventLikeFeed = async (eventId) => {
      return await MethodCall(getEventLikeFeedApi(eventId), "GET");
    };

    const getRoleByOrganization = async (orgId) => {
      return await MethodCall(getRoleByOrgAPI(orgId), "GET");
    };

    const getEventCommentFeed = async (payload) => {
      return await MethodCall(getEventCommentFeedApi, "POST", payload);
    };
    const getCancelEvents = async (payload) => {
      return await MethodCall(getCancelEventsApi, "POST", payload);
    };
    const getAllBudgets = async (getallBudgetPayload) => {
      return await MethodCall(getAllBudgetsApi, "POST", getallBudgetPayload);
    };

    const getBudgetDocData = async (BudgetId) => {
      const response = await MethodCall(getBudgetDocApi(BudgetId), "GET");
      return response;
    };

    const uploadBudgetDoc = async (payload, successMsg) => {
      return await MethodCall(
        uploadBudgetDocApi,
        "POST",
        payload,
        successMsg,
        true
      );
    };

    const saveBudgetDoc = async (payload, successMsg) => {
      const response = await MethodCall(
        saveBudgetRequestApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const updateBudgetDoc = async (payload, successMsg) => {
      const response = MethodCall(
        updateBudgetRequestApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const getPendingBudgetData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(getPendingBudgetsApi, "POST", payload);
      return response;
    };

    const getApprRejectBudgetData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getApprRejectBudgetsApi,
        "POST",
        payload
      );
      return response;
    };

    const approveBudgetData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        approveBudgetsApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const rejectBudgetData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        rejectBudgetsApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const deleteBudgetPlan = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        deleteBudgetPlanApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const getBudgetComments = async (ApprStatus, Id) => {
      const response = await MethodCall(
        getBudgetCommentsApi(ApprStatus, Id),
        "GET"
      );
      return response;
    };

    const sentNotification = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        sentNotificationApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const getAllBluePrints = async (getallBluePrintPayload) => {
      return await MethodCall(
        getAllBluePrintsApi,
        "POST",
        getallBluePrintPayload
      );
    };

    const getBluePrintDocData = async (BluePrintId) => {
      const response = await MethodCall(getBluePrintDocApi(BluePrintId), "GET");
      return response;
    };

    const uploadBluePrintDoc = async (payload, successMsg) => {
      return await MethodCall(
        uploadBluePrintDocApi,
        "POST",
        payload,
        successMsg,
        true
      );
    };

    const saveBluePrintDoc = async (payload, successMsg) => {
      const response = await MethodCall(
        saveBluePrintRequestApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const updateBluePrintDoc = async (payload, successMsg) => {
      const response = MethodCall(
        updateBluePrintRequestApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const getPendingBluePrintData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getPendingBluePrintsApi,
        "POST",
        payload
      );
      return response;
    };

    const getApprRejectBluePrintData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getApprRejectBluePrintsApi,
        "POST",
        payload
      );
      return response;
    };

    const approveBluePrintData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        approveBluePrintsApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const rejectBluePrintData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        rejectBluePrintsApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const getBluePrintComments = async (ApprStatus, Id) => {
      const response = await MethodCall(
        getBluePrintCommentsApi(ApprStatus, Id),
        "GET"
      );

      return response;
    };

    const getPendingFinalPlanData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getPendingFinalPlanApi,
        "POST",
        payload
      );
      return response;
    };

    const getApprovedFinalPlanData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getApprovedFinalPlanApi,
        "POST",
        payload
      );
      return response;
    };

    const approveFinalPlanData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        approveFinalPlanApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const updateRoleData = async (payLoad, successMsg) => {
      const response = await MethodCall(
        updateRoleAPI,
        "POST",
        payLoad,
        successMsg
      );
      return response;
    };

    const RoleData = ({ roleId, roleName, institutionId, recordStatus }) => ({
      id: roleId,
      itemName: roleName,
      value: roleName,
      institutionId: institutionId,
      status: recordStatus,
    });

    const getRoleData = async () => {
      const responseData = await MethodCall(getRoleApi, "GET");
      return responseData && responseData.data?.map(RoleData);
    };

    const fetchAndMapData = async (api, mapper) => {
      const responseData = await MethodCall(api, "GET");

      return responseData && responseData.data?.map(mapper);
    };
    const getFeedbackCategory = async (api, mapper, payLoad) => {
      const feedbackCategoryList = await MethodCall(api, "POST", payLoad);
      return feedbackCategoryList && feedbackCategoryList.data?.map(mapper);
    };
    const getFeedbackSubCategory = async (api, mapper, payLoad) => {
      const feedbackSubCategoryList = await MethodCall(api, "POST", payLoad);
      return (
        feedbackSubCategoryList && feedbackSubCategoryList.data?.map(mapper)
      );
    };

    const getFeedbackSubCategorybyCategory = async (api, mapper, payLoad) => {
      const feedbackSubCategoryListfromCategory = await MethodCall(
        api,
        "POST",
        payLoad
      );

      return (
        feedbackSubCategoryListfromCategory &&
        feedbackSubCategoryListfromCategory.data?.map(mapper)
      );
    };

    // const getRoleData = async (api, mapper) => {
    //   const responseData = await MethodCall(api, "GET");
    const getRightData = async (api, mapper) => {
      const responseData = await MethodCall(api, "GET");
      return responseData && responseData.data?.map(mapper);
    };
    // const getDesignationsData = async (api, mapper) => {
    //   // todo remove api argument from here
    //   const responseData = await MethodCall(api, "GET");
    //   return responseData && responseData.data?.map(mapper);
    // };

    const getSurveyData = async (api, mapper, payload) => {
      // todo remove api argument from here
      const responseData = await MethodCall(api, "POST", payload);
      return responseData && responseData.data?.map(mapper);
    };

    const getSuggestionData = async (api, mapper, payload) => {
      // todo remove api argument from here
      const responseData = await MethodCall(api, "POST", payload);
      return responseData && responseData.data.map(mapper);
    };

    const createFeedbackDropdown = async (mapper, payload) => {
      const responseData = await getFeedbackData(payload);
      return responseData && responseData.data?.map(mapper);
    };
    const createParkDropdown = async (mapper, payload) => {
      const responseData = await getParksData(payload);
      return responseData && responseData.data?.map(mapper);
    };

    const createVacantDropdown = async (mapper, payload) => {
      const responseData = await getVacantPlotsData(payload);
      return responseData && responseData.data?.map(mapper);
    };

    const getCHEDuserData = async (api, mapper) => {
      const responseData = await MethodCall(api, "GET");
      return responseData && responseData.data?.map(mapper);
    };
    const getCHEDsupervisorData = async (api, mapper) => {
      const responseData = await MethodCall(api, "GET");
      return responseData && responseData.data?.map(mapper);
    };
    const getKUOuserData = async (api, mapper) => {
      const responseData = await MethodCall(api, "GET");
      return responseData && responseData.data?.map(mapper);
    };

    //   return responseData && responseData.data?.map(mapper);
    // };

    const mapDesignationData = ({ id, desigName }) => ({
      id: id,
      itemName: desigName,
      value: desigName,
    });
    const mapInstitutionData = ({ id, instituteName }) => ({
      id: id,
      itemName: instituteName,
      value: instituteName,
    });
    const categoryData = ({ id, categoryName }) => ({
      id: id,
      itemName: categoryName,
      value: categoryName,
    });
    // const subCategoryData = ({ parentId, id, categoryName }) => ({
    //   parentId: parentId,
    //   id: id,
    //   itemName: categoryName,
    //   value: categoryName,
    // });

    const subCategoryData = ({ parentId, id, categoryName, metricId }) => ({
      parentId: parentId,
      id: id,
      itemName: categoryName,
      value: categoryName,
      metricId: metricId,
    });

    // const subCategoryDatafromCategory = ({  id, categoryName }) => ({

    //   id: id,
    //   itemName: categoryName,
    //   value: categoryName
    // });

    const subCategoryDatafromCategory = ({ id, categoryName }) => ({
      id: id,
      itemName: categoryName,
      value: categoryName,
    });

    // const RoleData = ({ roleId, roleName, recordStatus }) => ({
    //   id: roleId,
    //   itemName: roleName,
    //   value: roleName,
    //   status: recordStatus,
    // });

    const RightData = ({ id, rightsName }) => ({
      id: id,
      itemName: rightsName,
      value: rightsName,
    });

    // const SurveyData = ({
    //   id,
    //   surveyTitle,
    //   surveyDesc,
    //   surveyStatus,
    //   createdAt,
    //   modifiedAt,
    // }) => ({
    //   id: id,
    //   itemName: surveyTitle,
    //   value: surveyDesc,
    //   surveyStatus: surveyStatus,
    //   createdAt: createdAt,
    //   modifiedAt: modifiedAt,
    // });

    const SurveyData = ({
      id,
      surveyTitle,
      surveyDesc,
      surveyStatus,
      createdAt,
      modifiedAt,
      lastUpdatedName,
    }) => ({
      id: id,
      itemName: surveyTitle,
      value: surveyDesc,
      surveyStatus: surveyStatus,
      createdAt: createdAt,
      modifiedAt: modifiedAt,
      lastUpdatedName: lastUpdatedName,
    });

    const SuggestionData = ({
      id,
      surveyTitle,
      surveyDesc,
      surveyStatus,
      createdAt,
      modifiedAt,
      lastUpdatedName,
    }) => ({
      id: id,
      itemName: surveyTitle,
      value: surveyDesc,
      surveyStatus: surveyStatus,
      createdAt: createdAt,
      modifiedAt: modifiedAt,
      lastUpdatedName: lastUpdatedName,
    });

    const feedbackData = ({
      id,
      surveyTitle,
      surveyDesc,
      surveyStatus,
      createdAt,
      modifiedAt,
      lastUpdatedName,
    }) => ({
      id: id,
      itemName: surveyTitle,
      value: surveyDesc,
      surveyStatus: surveyStatus,
      createdAt: createdAt,
      modifiedAt: modifiedAt,
      lastUpdatedName: lastUpdatedName,
    });
    const parkData = ({ id, parkName, geoRefId }) => ({
      id: id,
      itemName: parkName,
      value: geoRefId,
    });

    const vacantplotData = ({ id, plotName, geoRefId }) => ({
      id: id,
      itemName: plotName,
      value: geoRefId,
    });

    const CHEDuserData = ({ id, firstName, lastName }) => ({
      id: id,
      itemName: firstName,
      value: lastName,
    });
    const CHEDsupervisorData = ({ id, firstName, lastName }) => ({
      id: id,
      itemName: firstName,
      value: lastName,
    });

    const KUOuserData = ({ id, firstName, lastName }) => ({
      id: id,
      itemName: firstName,
      value: lastName,
    });

    const getInstitutionList = async () => {
      const instituteDataList = await fetchAndMapData(
        getInstitutionApi,
        mapInstitutionData
      );
      return instituteDataList;
    };

    const getCategoryList = async () => {
      const categoryDataList = await getFeedbackCategory(
        getFeedbackCategoryApi,
        categoryData,
        categorypayLoadFeedback
      );
      return categoryDataList;
    };

    const getGrievancecategoryDataList = async () => {
      const grievancecategoryDataList = await getFeedbackCategory(
        getFeedbackCategoryApi,
        categoryData,
        //categorypayLoad
        grievancecategorypayLoad
      );
      return grievancecategoryDataList;
    };

    const getSubCategoryList = async () => {
      const subCategoryDataList = await getFeedbackSubCategory(
        getFeedbackSubCategoryApi,
        subCategoryData,
        categorypayLoadFeedback
      );
      return subCategoryDataList;
    };

    const getGrievancesubCategoryDataList = async () => {
      const grievancesubCategoryDataList = await getFeedbackSubCategory(
        getFeedbackSubCategoryApi,
        subCategoryData,
        //categorypayLoad
        grievancecategorypayLoad
      );
      return grievancesubCategoryDataList;
    };

    const getRightDataList = async () => {
      const rightDataList = await getRightData(getRightApi, RightData);
      return rightDataList;
    };

    const getSurveyList = async () => {
      const surveyList = await getSurveyData(
        getSurveyApi,
        SurveyData,
        getsurveypayLoad
      );
      return surveyList;
    };

    const getSuggestionList = async () => {
      const suggestionList = await getSuggestionData(
        getSuggestionApi,
        SuggestionData,
        getsuggestionpayLoad
      );
      return suggestionList;
    };

    const getFeedbackList = async () => {
      const feedbackList = await createFeedbackDropdown(
        feedbackData,
        getfeedbackpayLoad
      );
      return feedbackList;
    };
    const getParkDropdownList = async () => {
      const parkDropdownList = await createParkDropdown(
        parkData,
        getParkListPayLoad
      );
      return parkDropdownList;
    };
    const getVacantplotDropdownList = async () => {
      const vacantplotDropdownList = await createVacantDropdown(
        vacantplotData,
        getVacantPlotListPayLoad
      );
      return vacantplotDropdownList;
    };

    const setDropdownList = async (functionName, userTypeId) => {
      // Fetch all dropdown data

      let dropdownReduxPayload = {};

      try {
        if (functionName === "survey") {
          const surveyList = await getSurveyData(
            getSurveyApi,
            SurveyData,
            getsurveypayLoad
          );
          dropdownReduxPayload = {
            surveyList,
          };
        } else if (functionName === "feedbackCategory") {
          const feedbackList = await createFeedbackDropdown(
            feedbackData,
            getfeedbackpayLoad
          );
          dropdownReduxPayload = {
            feedbackList,
          };
        } else if (functionName === "suggestion") {
          const suggestionList = await getSuggestionData(
            getSuggestionApi,
            SuggestionData,
            getsuggestionpayLoad
          );
          dropdownReduxPayload = {
            suggestionList,
          };
        }

        //  else if (functionName === "desig") {
        //   const designationList = await getDesignationsData(
        //     getDesignationApi,
        //     mapDesignationData
        //   );
        //   dropdownReduxPayload = {
        //     designationList,
        //   };
        // }
        else {
          // const instituteDataList = await fetchAndMapData(
          //   getInstitutionApi,
          //   mapInstitutionData
          // );

          // const categoryDataList = await getFeedbackCategory(
          //   getFeedbackCategoryApi,
          //   categoryData,
          //   categorypayLoadFeedback
          // );

          // const grievancecategoryDataList = await getFeedbackCategory(
          //   getFeedbackCategoryApi,
          //   categoryData,
          //   //categorypayLoad
          //   grievancecategorypayLoad
          // );
          // const subCategoryDataList = await getFeedbackSubCategory(
          //   getFeedbackSubCategoryApi,
          //   subCategoryData,
          //   categorypayLoadFeedback
          // );
          // const grievancesubCategoryDataList = await getFeedbackSubCategory(
          //   getFeedbackSubCategoryApi,
          //   subCategoryData,
          //   //categorypayLoad
          //   grievancecategorypayLoad
          // );
          // const subCategoryDataListfromCategory =
          //   await getFeedbackSubCategorybyCategory(
          //     getFeedbackSubCategorybyCategoryApi,
          //     subCategoryDatafromCategory,
          //     subcategorypayLoad
          //   );

          // const roleDataList = await getRoleData(getRoleApi, RoleData);

          // const rightDataList = await getRightData(getRightApi, RightData);

          // const designationList = await getDesignationsData(
          //   getDesignationApi,
          //   mapDesignationData
          // );

          // const surveyList = await getSurveyData(
          //   getSurveyApi,
          //   SurveyData,
          //   getsurveypayLoad
          // );

          // const suggestionList = await getSuggestionData(
          //   getSuggestionApi,
          //   SuggestionData,
          //   getsuggestionpayLoad
          // );
          // const feedbackList = await createFeedbackDropdown(
          //   feedbackData,
          //   getfeedbackpayLoad
          // );
          // const parkDropdownList = await createParkDropdown(
          //   parkData,
          //   getParkListPayLoad
          // );
          // const vacantplotDropdownList = await createVacantDropdown(
          //   vacantplotData,
          //   getVacantPlotListPayLoad
          // );

          // -----------------------------------------------------------//

          // const CHEDuserList = await getCHEDuserData(
          //   getCHEDUserListApi,
          //   CHEDuserData
          // );

          // const CHEDsupervisorList = await getCHEDsupervisorData(
          //   getCHEDSupervisorListApi,
          //   CHEDsupervisorData
          // );

          // const KUOuserList = await getKUOuserData(
          //   getKUOUserListApi,
          //   KUOuserData
          // );

          // Conditional API calls based on user role

          let CHEDuserList = [];
          let KUOuserList = [];
          let CHEDsupervisorList = [];

          if (userTypeId === 1) {
            CHEDuserList = await getCHEDuserData(
              getCHEDUserListApi,
              CHEDuserData
            );
            CHEDsupervisorList = await getCHEDsupervisorData(
              getCHEDSupervisorListApi,
              CHEDsupervisorData
            );
            KUOuserList = await getKUOuserData(getKUOUserListApi, KUOuserData);
          }
          if (userTypeId === 6) {
            CHEDuserList = await getCHEDuserData(
              getCHEDUserListApi,
              CHEDuserData
            );
            CHEDsupervisorList = await getCHEDsupervisorData(
              getCHEDSupervisorListApi,
              CHEDsupervisorData
            );
            KUOuserList = await getKUOuserData(getKUOUserListApi, KUOuserData);
            // KUOuserList remains empty
          }

          // Dispatch the fetched data to the dropdown slicer
          const eventStatusList = [
            {
              id: 0,
              itemName: "Pending",
              value: 0,
            },
            {
              id: 1,
              itemName: "Published",
              value: 1,
            },
          ];

          dropdownReduxPayload = {
            // instituteDataList,
            // categoryDataList,
            // grievancecategoryDataList,
            // subCategoryDataList,
            // grievancesubCategoryDataList,
            //roleDataList,
            // rightDataList,
            designationList,
            // surveyList,
            // feedbackList,
            // suggestionList,
            CHEDuserList,
            CHEDsupervisorList,
            KUOuserList,
            // parkDropdownList,
            // vacantplotDropdownList,
            eventStatusList,
            //anotherDataList
          };
        }
      } catch (e) {
        console.error("Error: ", e);
      }

      dispatch({
        type: "SET_DROPDOWN_LISTS",
        payload: dropdownReduxPayload,
      });
    };

    const postDesignationData = async (savedesgpayload, desgsuccessMsg) => {
      const { designCode, desigName } = savedesgpayload;
      if (!hasValue(desigName)) {
        AppToast.error(designationErrMsg);
        return;
      }
      // if (!validationsCheck(designCode, "deptcode")) {
      //   AppToast.error(codeErrMsg);
      //   return
      // }
      if (!hasValue(designCode)) {
        AppToast.error(desigCodeErrMsg);
        return;
      }
      return await MethodCall(
        saveDesignationApi,
        "POST",
        savedesgpayload,
        desgsuccessMsg
      );
    };

    const assignRights = async (
      assignrightspayload,
      assignrightssuccessMsg
    ) => {
      return await MethodCall(
        assignRightApi,
        "POST",
        assignrightspayload,
        assignrightssuccessMsg
      );
    };

    // const getMyRightsData = async () => {
    //   const loginData = getLocalStorageItem(loginDataStorageKey);
    //   const roleId = loginData.roleId;
    //   const response = await MethodCall(
    //     getMyRightsApi + `?RoleId=${roleId}`,
    //     "GET"
    //   );
    //   return response;
    // };

    const getRolesNRightsData = async (roleId) => {
      const response = await MethodCall(getRolesNRightsApi(roleId), "GET");
      return response;
    };

    const getDesignationsData = async (deptId) => {
      const response = await MethodCall(getDesignationApi(deptId), "GET");
      return response && response.data.map(mapDesignationData);
    };

    const getDesignationsUnmappedData = async (deptId) => {
      const response = await MethodCall(getDesignationApi(deptId), "GET");
      return response;
    };

    const saveSurveyName = async (payLoad) => {
      const response = await MethodCall(addSurveyApi, "POST", payLoad);
      return response;
    };

    const saveSuggestionName = async (payLoad) => {
      const response = await MethodCall(addSuggestionApi, "POST", payLoad);
      return response;
    };

    const saveSuggestionData = async (payLoad, successMsg) => {
      const response = await MethodCall(
        saveSuggestionApi,
        "POST",
        payLoad,
        successMsg
      );
      return response;
    };

    const sentSuggestionNotification = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        sentSuggestionNotificationApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const getSuggestionDetails = async (payLoad) => {
      const response = await MethodCall(
        getSuggestionDetailsApi,
        "POST",
        payLoad
      );
      return response;
    };

    const getSuggestionAnalysis = async (payLoad) => {
      return await MethodCall(SuggestionAnalysisApi, "POST", payLoad);
    };

    const saveSurveyData = async (payLoad, successMsg) => {
      const response = await MethodCall(
        saveSurveyApi,
        "POST",
        payLoad,
        successMsg
      );
      return response;
    };

    const deleteSurveyData = async (delsurveypayload, delsurveysuccessMsg) => {
      return await MethodCall(
        updateSurveyApi,
        "POST",
        delsurveypayload,
        delsurveysuccessMsg
      );
    };

    const deleteSuggestionData = async (payload, successMsg) => {
      return await MethodCall(updateSuggestionApi, "POST", payload, successMsg);
    };

    const saveFeedbackName = async (payLoad) => {
      const response = await MethodCall(addFeedbackApi, "POST", payLoad);
      return response;
    };
    const updateFeedbackData = async (
      updatefeedbackpayload,
      updatefeedbacksuccessMsg
    ) => {
      return await MethodCall(
        updateFeedbackApi,
        "POST",
        updatefeedbackpayload,
        updatefeedbacksuccessMsg
      );
    };
    const saveFeedbackData = async (payLoad, successMsg) => {
      const response = await MethodCall(
        saveFeedbackApi,
        "POST",
        payLoad,
        successMsg
      );
      return response;
    };

    const deleteFeedbackData = async (
      delfeedbackpayload,
      delfeedbacksuccessMsg
    ) => {
      return await MethodCall(
        updateFeedbackApi,
        "POST",
        delfeedbackpayload,
        delfeedbacksuccessMsg
      );
    };

    const getUserList = async (payLoad) => {
      return await MethodCall(getUserListApi, "POST", payLoad);
    };

    // Get Feedback comments API
    const getFeedbackComments = async (payload) => {
      return await MethodCall(feedbackSurveyCommentsApi, "POST", payload);
    };
    const getSurveyAnalysis = async (payLoad) => {
      return await MethodCall(SurveyAnalysisApi, "POST", payLoad);
    };
    // Export FeedBack API
    const getExportSurveyFeedback = async (payload) => {
      return await MethodCallBlob(getExportSurveyFeedbackApi, "POST", payload);
    };

    const getFeedbackAnalysis = async (payLoad) => {
      return await MethodCall(FeedbackAnalysisApi, "POST", payLoad);
    };
    const getSurveyDetails = async (payLoad) => {
      const response = await MethodCall(getSurveyDetailsApi, "POST", payLoad);
      return response;
    };
    // const getSuggestionDetails = async (payLoad) => {
    //   const response = await MethodCall(getSurveyDetailsApi, "POST", payLoad);
    //   return response;
    // };
    const getFeedbackQADetails = async (payLoad) => {
      const response = await MethodCall(getFeedbackDetailsApi, "POST", payLoad);
      return response;
    };
    const getAllGrievances = async (payLoad) => {
      return await MethodCall(getAllGrevaincesApi, "POST", payLoad);
    };

    const getGrievanceData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(getGrievancesApi, "POST", payload);
      return response;
    };

    const getAssignedGrievanceData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getAssignedGrievancesApi,
        "POST",
        payload
      );
      return response;
    };

    const getGrievanceFilesData = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(getGrievancesFilesApi, "POST", payload);
      return response;
    };

    const getGrievanceComments = async (payload) => {
      // todo remove api argument from here
      const response = await MethodCall(
        getGrievancesCommentsApi,
        "POST",
        payload
      );
      return response;
    };

    const getUploadedFilesData = async (EntityType, FileName, FilePath) => {
      const response = await MethodCallBlob(
        getUploadedFilesApi(EntityType, FileName, FilePath),
        "GET"
      );
      return response;
    };

    const assignGrievanceData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        assignGrievanceApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const closedGrievanceData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        closeGrievanceApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const holdGrievanceData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        holdGrievanceApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const deassignGrievanceData = async (payload, successMsg) => {
      // todo remove api argument from here
      const response = await MethodCall(
        deassignGrievanceApi,
        "POST",
        payload,
        successMsg
      );
      return response;
    };

    const postUserCreationDetails = async (payLoad, successMsg) => {
      const {
        userName,
        userTypeId,
        deptId,
        desigId,
        instituteId,
        password,
        firstName,
        lastName,
        priMobileNo,
        secMobileNo,
        emailId,
        roleId,
      } = payLoad;

      if (!hasValue(userName.trim(), "userName")) {
        AppToast.error(useNameEmptyMsg);
        return;
      }

      // THE USER TYPE INPUT VALIDATION CHECKING MADE OFF & ISN'TAKEN WHILE CREATING NEW USER
      // if (!hasZeroValue(userTypeId)) {
      //   AppToast.error(useTypeErrMsg);
      //   return;
      // }

      if (!hasZeroValue(instituteId)) {
        AppToast.error(institutionErrMsg);
        return;
      }
      if (!hasZeroValue(deptId)) {
        AppToast.error(deptnameErrMsg);
        return;
      }
      if (!hasZeroValue(desigId)) {
        AppToast.error(designationErrMsg);
        return;
      }

      if (!hasZeroValue(roleId)) {
        AppToast.error(roleErrMsg);
        return;
      }
      if (!validationsCheck(password, "password")) {
        AppToast.error(passwordErrMsg);
        return;
      }
      if (!validationsCheck(priMobileNo, "phnNo")) {
        AppToast.error(phnNoErrMsg);
        return;
      }
      if (hasValue(secMobileNo)) {
        if (!validationsCheck(secMobileNo, "phnNo")) {
          AppToast.error(phnNoErrMsg);
          return;
        }
      }
      if (
        !validationsCheck(firstName, "name") ||
        !validationsCheck(lastName, "name")
      ) {
        AppToast.error(nameErrMsg);
        return;
      }
      if (!validationsCheck(emailId, "email")) {
        AppToast.error(emailErrMsg);
        return;
      }
      return await MethodCall(createUserApi, "POST", payLoad, successMsg);
    };
    const postUserUpdationDetails = async (payLoad, successMsg) => {
      return await MethodCall(updateUserApi, "POST", payLoad, successMsg);
    };

    // Map tabs
    const getNearByATM = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByATMApi, "POST", payload);
    };
    const getNearBusStop = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByBusStopApi, "POST", payload);
    };
    const getNearAutoTaxiStand = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearAutoTaxiStandApi, "POST", payload);
    };
    const getNearCellTowers = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearCellTowersApi, "POST", payload);
    };
    const getNearStreetLight = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearStreetLightApi, "POST", payload);
    };
    const getNearTransformer = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearTransformerApi, "POST", payload);
    };
    const getNearBridgeFlyOvers = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearBridgeFlyOversApi, "POST", payload);
    };
    const GetNearSubstation = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearSubstationApi, "POST", payload);
    };
    const GetNearRoadNetwork = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearRoadNetworkApi, "POST", payload);
    };
    const getNearByDrainage = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByDrainageApi, "POST", payload);
    };
    const getNearByFireStation = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByFireStationApi, "POST", payload);
    };
    const getNearByRailWays = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByRailWayStationApi, "POST", payload);
    };
    const getNearByMetroStation = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByMetroStationApi, "POST", payload);
    };
    const getNearByCommunityToilet = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByCommunityToiletApi, "POST", payload);
    };
    //
    const getNearByWaterSupply = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByWaterSupplyApi, "POST", payload);
    };
    const getNearByWaterBody = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByWaterBodyApi, "POST", payload);
    };
    const getNearByWaterMetro = async (payload) => {
      // todo remove api argument from here
      return await MethodCall(getNearByWaterMetroApi, "POST", payload);
    };
    // API FOR PROJECT MANAGEMENT
    const createProjectDetails = async (payload) => {
      const {
        projectName,
        projectDesc,
        institutionId,
        startDate,
        progressPerc,
        projectStatus,
        vendorName,
      } = payload;
      if (!hasValue(projectName)) {
        AppToast.error(projectNameErrorMsgForMandatoryFld);
        return false;
      }
      if (!validationsCheck(projectName, "projectName")) {
        AppToast.error(projectNameErrorMsg);
        return false;
      }
      if (!hasValue(projectDesc)) {
        AppToast.error(projectDescriptionErrorMsg);
        return false;
      }
      if (!hasValue(startDate)) {
        AppToast.error(projectStartDateErrorMsg);
        return false;
      }
      if (Number(institutionId) === 0) {
        AppToast.error(projectInstitutionErrorMsg);
        return false;
      }
      // if (!validationsCheck(progressPerc, "progressPerc")) {
      //   AppToast.error(projectProgressValidationErrorMsg);
      //   return false;
      // }
      if (Number(progressPerc) < 0 || Number(progressPerc) > 100) {
        AppToast.error(projectProgressRangeValidateErrorMsg);
        return false;
      }
      if (projectStatus === statusEnums.initiated && !hasValue(vendorName)) {
        AppToast.error(projectStatusErrorMsg);
        return false;
      }
      return await MethodCall(createProjectApi, "POST", payload);
    };

    // Update Project API Config.
    const updateProjectDetails = async (payload) => {
      const {
        projectName,
        projectDesc,
        institutionId,
        startDate,
        progressPerc,
        projectStatus,
        vendorName,
      } = payload;
      if (!hasValue(projectName)) {
        AppToast.error(projectNameErrorMsgForMandatoryFld);
        return false;
      }
      if (!validationsCheck(projectName, "projectName")) {
        AppToast.error(projectNameErrorMsg);
        return false;
      }
      if (!hasValue(projectDesc)) {
        AppToast.error(projectDescriptionErrorMsg);
        return false;
      }
      if (!hasValue(startDate)) {
        AppToast.error(projectStartDateErrorMsg);
        return false;
      }
      if (Number(institutionId) === 0) {
        AppToast.error(projectInstitutionErrorMsg);
        return false;
      }
      // if (!validationsCheck(progressPerc, "progressPerc")) {
      //   AppToast.error(projectProgressValidationErrorMsg);
      //   return false;
      // }
      if (Number(progressPerc) < 0 || Number(progressPerc) > 100) {
        AppToast.error(projectProgressRangeValidateErrorMsg);
        return false;
      }
      if (projectStatus === statusEnums.initiated && !hasValue(vendorName)) {
        AppToast.error(projectStatusErrorMsg);
        return false;
      }
      return await MethodCall(updateProjectApi, "POST", payload);
    };

    const getProjectDataList = async (payLoad) => {
      const response = await MethodCall(getProjectDataApi, "POST", payLoad);
      return response;
    };

    const getProjectCsv = async (payload) => {
      const response = await MethodCallBlob(getExportProjects, "POST", payload);
      return response;
    };

    // THESE USE-EFFECT TURNED OFF AND CALLED BELOW

    //useEffect(() => {
    //   setDropdownList();
    // }, []);

    const getDepartmentData = async (instituteId) => {
      const response = await MethodCall(getDepartmentApi(instituteId), "GET");
      return response;
    };

    const getParkInfo = async (payload) => {
      return await MethodCall(getParkInfoApi, "POST", payload);
    };

    const getGrievanceSummary = async (payload) => {
      return await MethodCall(getGrievancesSummeryApi, "POST", payload);
    };

    const getGrievanceInfo = async (payload) => {
      return await MethodCall(getGrievancesInfoApi, "POST", payload);
    };

    const getExportGrievance = async (payload) => {
      return await MethodCallBlob(getExportGrievanceApi, "POST", payload);
    };

    const getDashboardRatings = async (payload) => {
      return await MethodCall(getCategoryRatingApi, "POST", payload);
    };
    const getDashboardChart = async (payload) => {
      return await MethodCall(getDasboardChartApi, "POST", payload);
    };
    // const RightsMapper = ({
    //   roleId,
    //   roleName,
    //   rightsName,
    //   parentName,
    //   isView,
    //   isAdd,
    //   isEdit,
    //   isApprReject,
    //   isDownload,
    //   isUpload,
    //   recordStatus,
    // }) => ({
    //   roleId: roleId,
    //   itemName: roleName,
    //   rightsName: rightsName,
    //   parentName: parentName,
    //   isView: isView,
    //   isAdd: isAdd,
    //   isEdit: isEdit,
    //   isApprReject: isApprReject,
    //   isDownload: isDownload,
    //   isUpload: isUpload,
    //   recordStatus: recordStatus,
    // });

    const getMyRigths = async () => {
      return await MethodCall(getMyRightsApi, "GET");
    };

    // const getRoleSpecificRights = async () => {
    //   const responseData = await getMyRigths();
    //   const data = responseData.map(RightsMapper);
    //   return data;
    // };
    // const getMainNav = async () => {
    //   const rightsData = await getRoleSpecificRights();
    //   const roleBasedMenuItems = [];
    //   const menuItemsRights = {};
    //   Object.keys(MenuItems).forEach((menu) => {
    //     for (const menuRight of rightsData) {
    //       if (menu === menuRight.rightsName) {
    //         if (menuRight.isView === 1 && menuRight.parentName === "") {
    //           // If the item has no parent, push it directly to the role-based menu
    //           const menuItem = { ...MenuItems[menuRight.rightsName], content: [] };
    //           roleBasedMenuItems.push(menuItem);

    //           menuItemsRights[menuRight.rightsName] = {
    //             isView: menuRight.isView,
    //             isAdd: menuRight.isAdd,
    //             isEdit: menuRight.isEdit,
    //             isApprReject: menuRight.isApprReject,
    //             isDownload: menuRight.isDownload,
    //             isUpload: menuRight.isUpload,
    //           };
    //         } else if (menuRight.isView === 1 && menuRight.parentName !== "") {
    //           // If the item has a parent, find the parent in the role-based menu and add it to the parent's content
    //           const parentMenu = roleBasedMenuItems.find(
    //             (menu) => menu.accessName === menuRight.parentName
    //           );

    //           if (parentMenu) {
    //             const menuItem = {
    //               ...MenuItems[menuRight.rightsName],
    //               content: [],
    //             };
    //             parentMenu.content.push(menuItem);

    //             menuItemsRights[menuRight.rightsName] = {
    //               isView: menuRight.isView,
    //               isAdd: menuRight.isAdd,
    //               isEdit: menuRight.isEdit,
    //               isApprReject: menuRight.isApprReject,
    //               isDownload: menuRight.isDownload,
    //               isUpload: menuRight.isUpload,
    //             };
    //           }
    //         }
    //         // break the inner loop as one menu occurs once in the rightsData
    //         break;
    //       }
    //     }
    //   });
    //   // setLocalStorageItem(menuItemsRightsDetails, menuItemsRights);
    //   return roleBasedMenuItems;
    // };
    const RightsMapper = ({
      roleId,
      roleName,
      rightsName,
      parentName,
      isView,
      isAdd,
      isEdit,
      isApprReject,
      isDownload,
      isUpload,
      recordStatus,
    }) => ({
      roleId: roleId,
      itemName: roleName,
      rightsName: rightsName,
      parentName: parentName,
      isView: isView,
      isAdd: isAdd,
      isEdit: isEdit,
      isApprReject: isApprReject,
      isDownload: isDownload,
      isUpload: isUpload,
      recordStatus: recordStatus,
    });
    // const getMainNav = async () => {
    //   const rightsData = await getRoleSpecificRights();
    //   const roleBasedMenuItems = [];
    //   const menuItemsRights = {};
    //   Object.keys(MenuItems).forEach((menu) => {
    //     for (const menuRight of rightsData) {
    //       if (menu === menuRight.rightsName) {
    //         if (menuRight.isView === 1 && menuRight.parentName === "") {
    //           // If the item has no parent, push it directly to the role-based menu
    //           const menuItem = { ...MenuItems[menuRight.rightsName], content: [] };
    //           roleBasedMenuItems.push(menuItem);

    //           menuItemsRights[menuRight.rightsName] = {
    //             isView: menuRight.isView,
    //             isAdd: menuRight.isAdd,
    //             isEdit: menuRight.isEdit,
    //             isApprReject: menuRight.isApprReject,
    //             isDownload: menuRight.isDownload,
    //             isUpload: menuRight.isUpload,
    //           };
    //         } else if (menuRight.isView === 1 && menuRight.parentName !== "") {
    //           // If the item has a parent, find the parent in the role-based menu and add it to the parent's content
    //           const parentMenu = roleBasedMenuItems.find(
    //             (menu) => menu.accessName === menuRight.parentName
    //           );

    //           if (parentMenu) {
    //             const menuItem = {
    //               ...MenuItems[menuRight.rightsName],
    //               content: [],
    //             };
    //             parentMenu.content.push(menuItem);

    //             menuItemsRights[menuRight.rightsName] = {
    //               isView: menuRight.isView,
    //               isAdd: menuRight.isAdd,
    //               isEdit: menuRight.isEdit,
    //               isApprReject: menuRight.isApprReject,
    //               isDownload: menuRight.isDownload,
    //               isUpload: menuRight.isUpload,
    //             };
    //           }
    //         }
    //         // break the inner loop as one menu occurs once in the rightsData
    //         break;
    //       }
    //     }
    //   });
    //   // setLocalStorageItem(menuItemsRightsDetails, menuItemsRights);
    //   return roleBasedMenuItems;
    // };
    const getRoleSpecificRights = async () => {
      try {
        const responseData = await getMyRigths();
        if (responseData.succeeded) {
          const { data } = responseData;
          const rightsData = data?.map(RightsMapper);

          const roleBasedMenuItems = [];
          const menuItemsRights = {};
          Object.keys(MenuItems).forEach((menu) => {
            for (const menuRight of rightsData) {
              if (menu === menuRight.rightsName) {
                if (menuRight.isView === 1 && menuRight.parentName === "") {
                  // If the item has no parent, push it directly to the role-based menu
                  const menuItem = {
                    ...MenuItems[menuRight.rightsName],
                    content: [],
                  };
                  roleBasedMenuItems.push(menuItem);

                  menuItemsRights[menuRight.rightsName] = {
                    isView: menuRight.isView,
                    isAdd: menuRight.isAdd,
                    isEdit: menuRight.isEdit,
                    isApprReject: menuRight.isApprReject,
                    isDownload: menuRight.isDownload,
                    isUpload: menuRight.isUpload,
                  };
                } else if (
                  menuRight.isView === 1 &&
                  menuRight.parentName !== ""
                ) {
                  // If the item has a parent, find the parent in the role-based menu and add it to the parent's content
                  const parentMenu = roleBasedMenuItems.find(
                    (menu) => menu.accessName === menuRight.parentName
                  );

                  if (parentMenu) {
                    const menuItem = {
                      ...MenuItems[menuRight.rightsName],
                      content: [],
                    };
                    parentMenu.content.push(menuItem);

                    menuItemsRights[menuRight.rightsName] = {
                      isView: menuRight.isView,
                      isAdd: menuRight.isAdd,
                      isEdit: menuRight.isEdit,
                      isApprReject: menuRight.isApprReject,
                      isDownload: menuRight.isDownload,
                      isUpload: menuRight.isUpload,
                    };
                  }
                }
                // break the inner loop as one menu occurs once in the rightsData
                break;
              }
            }
          });
          dispatch(storeMenuSlice({ data: roleBasedMenuItems }));
          dispatch(storeRoleRightsSlice({ data: menuItemsRights }));
          // dispatch(storeMenuSlice({ data: roleBasedMenuItems }));
          // setLocalStorageItem(menuItemsRightsDetails, menuItemsRights);
          // return roleBasedMenuItems;
        }
      } catch (error) {}
    };
    const MenuSlice = useSelector((state) => state.MenuSlice);
    const RoleRightsSlice = useSelector((state) => state.RoleRightsSlice);

    // THESE USE-EFFECT TURNED OFF AND CALLED BELOW
    useEffect(() => {
      try {
        window.scrollTo(0, 0);
        // let _previousRoute = [...previousRoutes, location.pathname].slice(-2);
        let _previousRoute = [...previousRoutes, pathname].slice(-2);

        setPreviousRoutes(_previousRoute);
        setPreviousRoute(_previousRoute[0]);
      } catch (e) {
        console.error("Error: ", e);
      }
    }, [pathname]);

    // THESE USE-EFFECT TURNED OFF AND CALLED BELOW
    // useEffect(() => {
    //   if (userTypeId) {
    //     setDropdownList("yourFunctionName", userTypeId);
    //   }
    // }, [userTypeId]);

    //  A SINGLE USE-EFFECT IS CALLED HERE
    useEffect(() => {
      try {
        if (
          loggedinInfoRedux?.data?.accessToken &&
          MenuSlice.data.length === 0
        ) {
          // setDropdownList();
          getRoleSpecificRights();
        }

        if (userTypeId) {
          setDropdownList("yourFunctionName", userTypeId);
        }
      } catch (e) {
        // console.error("Error: ", e);
      }
    }, [loggedinInfoRedux?.data?.accessToken, userTypeId]);

    return (
      <GlobalContext.Provider
        value={{
          postLoginData,
          postDesignationData,
          postDepartmentData,
          getDesignationsData,
          setDropdownList,
          getDepartmentData,
          assignRights,
          getRolesNRightsData,
          //getMyRightsData,
          getUserList,
          postUserCreationDetails,
          postUserUpdationDetails,
          saveSurveyName,
          saveSurveyData,
          deleteSurveyData,
          saveFeedbackName,
          updateFeedbackData,
          getSurveyAnalysis,
          getFeedbackComments,
          getFeedbackAnalysis,
          saveFeedbackData,
          deleteFeedbackData,
          getSurveyDetails,
          getFeedbackQADetails,
          getFeedbackData,
          publishFeedbackData,
          unpublishFeedbackData,
          getGrievanceData,
          getAssignedGrievanceData,
          getGrievanceFilesData,
          getUploadedFilesData,
          assignGrievanceData,
          closedGrievanceData,
          holdGrievanceData,
          deassignGrievanceData,
          getGrievanceComments,
          getParksData,
          getParkDetailsData,
          getVacantPlotsData,
          getVacantPlotDetailsData,
          getWardsData,
          getWardDetailsData,
          uploadEventThumbnail,
          uploadBanner,
          saveEvents,
          getAllEvents,
          getEventFilesData,
          getAllBudgets,
          getBudgetDocData,
          uploadBudgetDoc,
          saveBudgetDoc,
          updateBudgetDoc,
          getPendingBudgetData,
          getApprRejectBudgetData,
          approveBudgetData,
          rejectBudgetData,
          deleteBudgetPlan,
          getBudgetComments,
          sentNotification,
          getAllBluePrints,
          getBluePrintDocData,
          uploadBluePrintDoc,
          saveBluePrintDoc,
          updateBluePrintDoc,
          getPendingBluePrintData,
          getApprRejectBluePrintData,
          approveBluePrintData,
          rejectBluePrintData,
          getBluePrintComments,
          getPendingFinalPlanData,
          getApprovedFinalPlanData,
          approveFinalPlanData,
          getNearByATM,
          getNearBusStop,
          getNearAutoTaxiStand,
          getNearCellTowers,
          getNearStreetLight,
          getNearTransformer,
          GetNearSubstation,
          GetNearRoadNetwork,
          getNearBridgeFlyOvers,
          getNearByDrainage,
          getNearByFireStation,
          getNearByRailWays,
          getNearByMetroStation,
          getNearByCommunityToilet,
          getNearByWaterSupply,
          getNearByWaterBody,
          getNearByWaterMetro,
          getSurveyDataNew,
          getParkInfo,
          getGrievanceSummary,
          getGrievanceInfo,
          getExportGrievance,
          getDashboardRatings,
          getDashboardChart,
          getParkListByEvents,
          GetNearSubstation,
          getEventLikeFeed,
          getEventCommentFeed,
          getCancelEvents,
          createProjectDetails,
          updateProjectDetails,
          getProjectDataList,
          getMyRigths,
          getRoleByOrganization,
          getRoleData,
          updateRoleData,
          postForgetPassword,
          validateOtp,
          resetWebPassword,
          getDesignationsUnmappedData,
          getSuggestionDataNew,
          saveSuggestionName,
          saveSuggestionData,
          deleteSuggestionData,
          getSuggestionDetails,
          getSuggestionAnalysis,
          sentSuggestionNotification,
          getExportSurveyFeedback,
          getInstitutionList,
          getCategoryList,
          getGrievancecategoryDataList,
          getSubCategoryList,
          getGrievancesubCategoryDataList,
          getRightDataList,
          getSurveyList,
          getSuggestionList,
          getFeedbackList,
          getParkDropdownList,
          getVacantplotDropdownList,
          getProjectCsv,
          previousRoute,
        }}
      >
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <App />
        {/* </PersistGate > */}
      </GlobalContext.Provider>
    );
  };
  const WrappedApp = ServerRequiestWrapper(Api);
  return <WrappedApp />;
};
export default AppWrapper;
